import React from "react";

const Pagination = ({ postsPerPage, totalPosts, paginate, active }) => {
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <nav>
      <ul className="pagination mx-2">
        {pageNumbers.map((number) => (
          <li
            key={number}
            className={active == number?  "active" + " page-item" :'page-item'}
          >
            <a onClick={() => paginate(number)} className="page-link">
              {number}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Pagination;
