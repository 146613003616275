import React, { useState, useEffect } from "react";

// Services
import TeamsService from "../../../services/teams.service.js";
import AuthService from "../../../services/auth.service.js";
import PartnerService from "../../../services/partners.service.js";

// Components
import {
    FormGroup,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    Container,
    Row,
    Table,
    Label,
    Input
} from "reactstrap";
import { Filter, Settings } from "react-feather";
import { Button } from "react-bootstrap";
import Pagination from "../../../../src/components/Pagination";

// Assets
import avatar4 from "../../../assets/img/avatars/avatar.png";


var currentUser = AuthService.getCurrentUser();
var currentCompany = AuthService.getCurrentCompany();



{/*...................................................member list code part..............................................................*/ }
/*
* Partner Profile view
*
* This view is responsible of displaying all users within
* a Partner.
*
* TODO: Needs to be fine tuned
* */
const UsersList = (props) => {

    if (currentUser == null || currentUser == undefined) {
        props.history.push("/");
        window.location.reload();
    }
     
    var partnerData = props.props.history.location.state.pName;
    var partnerId = props.props.history.location.state.data;

    const [usersList, setUsersList] = useState([]);


    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(50);
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;

    //use for users checkbox 
    const [nonUsers, setNonUsers] = useState([]);
    const [arrowPositionFirstName, setArrowPositionFirstName] = useState('arrow down');
    const [arrowPositionLastName, setArrowPositionLastName] = useState('arrow down');
    const [arrowPositionRole, setArrowPositionRole] = useState('arrow down');
    const [state, setState] = useState({
        data: [],
        sort: {
            column: null,
            direction: 'desc',
        },
    });

    // Change page
    const paginate = pageNumber => setCurrentPage(pageNumber);

    // Initial load
    useEffect(() => {

        if (partnerId === null) {
            partnerId = 0;
        }

        if (usersList.length === 0) {
             
            const partnerUserList = PartnerService.GetPartnerUserDetailsByPartnerId(partnerId);

            partnerUserList.then((response) => {
                return response;
            })
                .then((data) => {

                    setUsersList(data);
                    setState({ data: data });

                })
                .catch(e => {
                    console.log(e);
                })
        }



    }, []);


    // Column sort function
    const onSort = (column) => {

        const direction = state.sort != undefined && state.sort.column ? (state.sort.direction === 'asc' ? 'desc' : 'asc') : 'desc';
        const sortedData = state.data.sort((a, b) => {
            if (column === 'FirstName') {
                if (direction === 'desc') {
                    setArrowPositionFirstName('arrow down');
                }
                else {
                    setArrowPositionFirstName('arrow up');

                }
                const nameA = a.FirstName.toUpperCase(); // ignore upper and lowercase
                const nameB = b.FirstName.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }

                // names must be equal
                return 0;
            }
            if (column === 'LastName') {
                if (direction === 'desc') {
                    setArrowPositionLastName('arrow down');
                }
                else {
                    setArrowPositionLastName('arrow up');

                }
                const nameA = a.LastName.toUpperCase(); // ignore upper and lowercase
                const nameB = b.LastName.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }

                // names must be equal
                return 0;
            }
            if (column === 'Role') {
                if (direction === 'desc') {
                    setArrowPositionRole('arrow down');
                }
                else {
                    setArrowPositionRole('arrow up');

                }
                const nameA = a.Role.toUpperCase(); // ignore upper and lowercase
                const nameB = b.Role.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }

                // names must be equal
                return 0;
            }

        });

        if (direction === 'desc') {
            setUsersList(sortedData.reverse());

        }
        setState({
            data: sortedData,
            sort: {
                column,
                direction,
            }
        });
    };


    {/*...................................................END of member list code part..............................................................*/ }



    {/*................................. code for team leader part section - left............................................................ */ }
    const [partnerName, setPartnerName] = useState("");
    useEffect(() => {
        //if there is no teamid then set to 0
        if (partnerData === null) {
            setPartnerName('');
        }
        else {
            setPartnerName(partnerData);
        }
    });


    {/*...............................filter view  section code........................................................*/ }
    const [filterViewShow, setFilterViewShow] = useState(false);
    const [filterRoleName, setFilterRoleName] = useState(0);
    const [nameFilterProfile, setNameFilterProfile] = useState(null);
    const onFilterButtonClick = () => {

        let data1 = usersList;
        if (nameFilterProfile != null && nameFilterProfile !== undefined && nameFilterProfile !== "") {
            data1 = data1.filter(x => x.FirstName.toUpperCase().indexOf(nameFilterProfile.toUpperCase()) > -1 || x.LastName.toUpperCase().indexOf(nameFilterProfile.toUpperCase()) > -1)
        }
        if (filterRoleName != 0) {
            data1 = data1.filter(x => x.roleName == filterRoleName)
        }
        setState({ data: data1 });
    }

    const onChangefilterRoleName = (e) => {
        setFilterRoleName(e.target.value);
    }

    const onChangeNameProfile = (e) => {
        const name = e.target.value;
        setNameFilterProfile(name);
    }

    const filterView = () => {

        setFilterViewShow(s => !s);
    };


    {/*...............................filter view  section........................................................*/ }

    if (currentUser || currentCompany) {

        return (

            <>
                <Row className="mb-2 mb-xl-4">
                    <Col xs="auto" className="ml-auto text-right mt-n1">

                        <Button color="primary" className="shadow-sm mr-1" onClick={filterView} >
                            <Filter className="feather" />
                        </Button>

                        <Button color="primary" className="shadow-sm">
                            <Settings className="feather" />
                        </Button>

                    </Col>
                </Row>




                <Row>
                    <Col md={filterViewShow ?
                        "2" : "4"} >
                        <Card>
                            <CardHeader>
                                <h1 className="mb-0 h3">
                                    Partner
                                </h1>
                            </CardHeader>
                            <CardBody className="text-center">
                                <img
                                    src={avatar4}
                                    alt="Varun ameta"
                                    className="img-fluid rounded-circle mb-2"
                                    width="128"
                                    height="128"
                                />

                            </CardBody>

                            <br />

                            <CardBody>
                                <CardTitle tag="h6" >Partner Name</CardTitle>
                                <h4 className="mb-0">{partnerName}</h4>
                            </CardBody>
                            <hr className="my-0" />



                            <CardBody>
                                <p>Description of Partner.
                                Description of team info.</p>
                            </CardBody>


                        </Card>
                    </Col>





                    <Col md={filterViewShow ?
                        "6" : "8"} >
                        <Card>
                            <CardHeader>
                                <h1 className="mb-0 h3">
                                    Users
                                </h1>

                            </CardHeader>
                            <CardBody>

                                {/*model for add team */}
                                {/*model for add team */}




                                <Table striped hover>
                                    <thead>
                                        <tr>

                                            <th>  <button
                                                type="button"
                                                className="SortButton" onClick={() => onSort('FirstName')}>
                                                First Name    <i className={arrowPositionFirstName}></i>
                                            </button></th>
                                            <th>  <button
                                                type="button"
                                                className="SortButton" onClick={() => onSort('LastName')}>
                                                Last Name    <i className={arrowPositionLastName}></i>
                                            </button></th>
                                            <th>  <button
                                                type="button"
                                                className="SortButton" onClick={() => onSort('Role')}>
                                                Role    <i className={arrowPositionRole}></i>
                                            </button></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {state.data.slice(indexOfFirstPost, indexOfLastPost).map(tu => {
                                            return <tr key={tu.id}>
                                                <td>{tu.FirstName}</td>
                                                <td>{tu.LastName}</td>
                                                <td>{tu.roleName}</td>
                                            </tr>;
                                        })}
                                    </tbody>
                                </Table>
                                {/*Table for user list*/}
                                <Pagination
                                    postsPerPage={postsPerPage}
                                    totalPosts={usersList.length}
                                    paginate={paginate}
                                />

                            </CardBody>
                        </Card>

                    </Col>

                    {/*...............................filter view ........................................................*/}
                    {filterViewShow ?
                        <Col md={4} >
                            <Card>
                                <CardHeader>

                                    <div style={{ float: "right" }}>
                                        <Button onClick={() => setFilterViewShow(false)} >close</Button>
                                    </div>
                                    <h1 className="mb-0 h3  text-center">
                                        Filter
                                </h1>
                                </CardHeader>
                                <CardBody >
                                    <Col xs={12} md={12}>
                                        <FormGroup>
                                            <Label> Name</Label>
                                            <Input
                                                bsSize="lg"
                                                type="text"
                                                name="name"
                                                placeholder="Name" value={nameFilterProfile} onChange={onChangeNameProfile}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col xs={12} md={12}>
                                        <FormGroup>
                                            <Label>Role</Label>
                                            <select className="custom-select"
                                                value={filterRoleName}
                                                onChange={onChangefilterRoleName}
                                            >
                                                <option value="0">Select Role</option>
                                                {usersList.filter((v, i, a) => a.findIndex(t => (t.roleName === v.roleName)) === i).map(schema => {
                                                    return <option key={schema.roleName} value={schema.roleName}>{schema.roleName}</option>;
                                                })}


                                            </select>
                                        </FormGroup>
                                    </Col>

                                    <Col xs={12} md={12}>
                                        <div className="text-center">
                                            <Button variant="primary" onClick={onFilterButtonClick} >
                                                Filter
                                    </Button>
                                        </div>
                                    </Col>

                                </CardBody>



                            </Card>
                        </Col>
                        : <div></div>}
                </Row>
            </>
        );
    }

    else {
        return (<Card></Card>)
    }
}

const PartnerView = (props) => (
    <div id="partner_view_div">
        <UsersList props={props} />
    </div>
);

export default PartnerView;
