import React, { useState, useRef, useEffect } from "react";

// Services
import AuthService from "../../../services/auth.service.js";
import assessmentService from "../../../services/assessment.service.js";


// Components
import { Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import {  Modal } from "react-bootstrap";
// Assets
import Watch from "../../../assets/img/icons/watch.png";
// import right from "../../../assets/img/icons/right.png";

/*
* Time Based assessment view
*
* This view is responsible for displaying assessment questions & recording the answers
*
* TODO: Combine non time based & time based to a single component
*
* */
const QuizView = (props) => {
    var currentUser = AuthService.getCurrentUser();
    if (currentUser.roleName === "super-admin") {
        props.history.push("/dashboard/default");
        window.location.reload();
    }
    if (currentUser == null || currentUser == undefined) {
        props.history.push("/");
        window.location.reload();
    }

    if (props.location.state == null || props.location.state == undefined) {
        props.history.push({
            pathname: "/Assessments",
        });
        window.location.reload();
    }
    if (props.history.action == "POP") {
        var isKeyPresent = JSON.parse(sessionStorage.getItem("AssessmentQuizKey"));
        if (isKeyPresent == null) {
            props.history.push({
                pathname: "/Assessments",
            });
            window.location.reload();
        }
    }
    const titleRef = useRef();
    //fetching questionObject from props as passed from assessment home page
    var questions = props.location.state.questionsList;
    //declaring other const for quiz related functionality
    const [questionCounter, setQuestionCounter] = useState(0);
    const TitleList = questions;
    // const [iconlist,setIconlist] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    //declaring local variables for side bar qestion highlighting
    var count = 0;
    var topicNavCount = 0;
    // var arr =[];
    console.log(props.location.state);
    const formatToHoursAndMinutes = (secondsVal) => {
        
            var sec_num = parseInt(secondsVal, 10);
            var hours = Math.floor(sec_num / 3600);
            var minutes = Math.floor(sec_num / 60) % 60;
            var seconds = sec_num % 60;

            return [hours, minutes, seconds]
                .map((v) => (v < 10 ? "0" + v : v))
                .filter((v, i) => v !== "00" || i > 0)
                .join(":");
        
        
    };
    const Start = () => {
        var currentUser = AuthService.getCurrentUser();

        var details = assessmentService.AddEntryInUserTestTable(currentUser.id, props.location.state.assessmentId, props.location.state.creditId).then((response) => {
            return response;
        })
            .then((data) => {
                props.history.push({
                    pathname: '/AssessmentQuiz',
                    state: { assessmentId: props.location.state.assessmentId, questionsList: props.location.state.questionsList, creditId: props.location.state.creditId }
                })
            })
            .catch(e => {
                console.log(e);
            })
    }
    return (
        <>
            <Row>
                <div className="col-lg-8 col-md-8" style={{color:"#131523"}}>
                    <Card className="my-card assessment-wrapper-home">
                        <CardBody className="pb-0">
                            <h4>Instructions</h4>
                            <span>
                                {props.location.state.questionsList.WelcomeText}
                            </span>
                        </CardBody>
                        <hr className="my-hr" />
                        <CardBody style={{padding: "0 1.25rem"}}>
                            <Row>
                                <div className="col-lg-1 col-md-1">
                                    <div className="question-number rounded-circle text-white font-weight-bold h3">
                                       1
                                    </div>
                                </div>
                                <div className="col-lg-11 col-md-11">
                                    <p> This assessment specifically targets the fundamental capabilities that are essential for achieving effectiveness in this field.</p>
                                </div>
                            </Row>
                            <div style={{paddingLeft:"9%"}}>
                                <h5 style={{fontWeight:"700",fontSize:"18px"}}>The dimensions are:</h5>
                                <Row style={{width:"100%"}}>
                                    {TitleList.TitleList.map((item,index)=>(
                                        <div className="col-lg-6 col-md-6" key={index} style={{margin: "0.2rem 0",fontWeight:"600",fontSize:"18px"}}>{index +1}.&nbsp;{item.TitleName}</div>
                                    ))}
                                </Row>                             
                            </div>   
                        </CardBody>
                        <hr className="my-hr" />

                        <CardBody style={{padding: "0 1.25rem"}}>
                            <Row>
                                <div className="col-lg-1 col-md-1">
                                    <div className="question-number rounded-circle text-white font-weight-bold h3">
                                       2
                                    </div>
                                </div>
                                <div className="col-lg-11 col-md-11">
                                    <p>Quick heads up! If you've not already done it, please take a moment to fill out your profile before you start the assessment. We're keen to know a bit about your role and experience. Your answers will help provide a backdrop for your assessment. You can easily tweak this information before you start the assessment. Click on your 'Name' at the top and choose 'Profile'. </p>
                                </div>
                            </Row>
                        </CardBody>
                        <hr className="my-hr" />


                        <CardBody style={{padding: "0 1.25rem"}}>
                            <Row className="align-items-top">
                                    <div className="col-lg-1 col-md-1">
                                        <div className="question-number rounded-circle text-white font-weight-bold h3">
                                        3
                                        </div>
                                    </div>
                                    <div className="col-lg-11 col-md-11">
                                        <p>On completion of the assessment, you will receive a PDF report of your results. This may happen automatically or at a later stage depending on what was agreed with your organisation. The report captures your expertise at a point in time, which you can always keep for reference. Plus, it's an excellent tool for facilitating a structured discussion with your manager.</p>
                                    </div>
                            </Row>
                        </CardBody>
                        <CardBody style={{padding: "0 1.25rem"}}>
                            <Row className="align-items-top">
                                <div className="col-lg-8 col-md-8 mt-5 mb-4">
                                    <button
                                        id="submit-button"
                                        className="quiz-submit-btn btn btn-primary d-block mr-0 ml-auto"
                                       onClick={Start}
                                    >
                                        START ASSESSMENT
                                    </button>
                                </div>
                            </Row>
                        </CardBody>
                       
                    </Card>
                </div>
                <div className="col-lg-4 col-md-4">
                    <Card
                        className="my-card"
                        style={{
                            maxHeight: "705px",
                        }}
                    >
                        {questions.DefaultTime !=='0 minutes' ?
                        <>
                        <CardHeader className="pb-0">
                            <Row>
                                <div className="col-lg-12 col-md-12">
                                    <Row>
                                        <Col sm={4} md={4}>
                                            <img
                                                src={Watch}
                                                className="img-responsive mt-2 mx-auto d-block"
                                                width="80"
                                                height="80"
                                            />
                                        </Col>
                                        <Col sm={8} md={8}>
                                            <div>
                                                <p className="mb-0">Your time left</p>
                                                <h3 className="display-4 font-weight-bold text-dark" style={{fontSize:"2.9rem"}}>
                                                    {formatToHoursAndMinutes(props.location.state.questionsList.AssessmentDefaultTime)}
                                                </h3>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Row>
                        </CardHeader>
                        <hr className="my-hr" />
                        </>:<div style={{marginTop: "2rem",width:'100%'}}></div>
                        }
                        

                        <div className="scrollbar1" style={{maxHeight: '35rem',overflow: 'scroll'}}>
                        {questions.DefaultTime !=='0 minutes' &&
                        <>
                            <CardBody
                                className="py-0"
                                style={{
                                    minHeight: "auto",
                                }}
                            >
                                <Row>
                                    <div className="h1 font-weight-bold text-dark text-center px-2" style={{width:'100%',fontSize:'1.75rem'}}>
                                    THIS IS A <span style={{color:'red'}}>TIMED TASK!</span>
                                    </div>
                                    <p style={{textAlign:'center',fontSize:'0.8rem',padding:"0 5px"}}>
                                        You've got a strict {questions.DefaultTime} timeframe to complete this evaluation. When you click ‘begin assessment’ the countdown begins. <br></br>
                                        With eight sections to cover, try not to spend more than 5 minutes on each - that way, you'll get through all the sections in about 40 minutes. Use the remaining 20 minutes to review your answers and double-check anything you're unsure about.<br></br>
                                        IMPORTANT: If you don't finish within the {questions.DefaultTime} window, you'll be timed out and won't be able to access it again. So, keep an eye on the clock and make sure you submit on time. Best of luck - you've got this!<br></br>
                                    </p>
                                </Row>
                            </CardBody>
                            <hr className="my-hr" />
                        </>
                        }
                        <CardBody
                            className="py-0"
                            style={{
                                minHeight: "auto",
                            }}
                        >
                            <Row>
                                <h2 className="h1 font-weight-bold text-dark text-left px-2">
                                    Your Assessment Progress
                                </h2>
                            </Row>
                        </CardBody>
                        <hr className="my-hr" />
                        <CardBody className="py-0 scrollbar1" style={{overflowY: "scroll"}}>
                            <Row>
                                <Col md={12} sm={12}>
                                    {/*sidebar*/}
                                    <div className="my-sidenav">
                                        <div>
                                            {TitleList.TitleList.map((x,index) => (
                                                <div id={(topicNavCount += 1)} ref={titleRef} key={index}>
                                                    {" "}
                                                        <div className="right-sidebar-h5 pl-2"
                                                            style={{borderRadius:'0.3rem',fontWeight: '800'}}
                                                        >{x.TitleName}</div>
                                                        {/* <Card className="my-question-card mb-2 pl-1 pt-2 shadow-none" style={{fontSize:'0.7rem'}}>
                                                            {x.TitleDescription}
                                                        </Card> */}
                                                    <hr className="my-hr" />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                        </div>
                        
                    </Card>
                </div>
            </Row>
        </>
    );
};

const StartassessmentQuiz = (props) => (
    <>
        <h2>Welcome to the {props.location.state.questionsList.AssessmentName}</h2>
        <QuizView {...props} />
    </>
);

export default StartassessmentQuiz;
