import axios from "axios";

const API_URL = process.env.REACT_APP_TEAMLEADER_API_URL;

const GetTeamUsersListByTeamId = (id) => {
    return axios
        .get(API_URL + "GetTeamUsersListByTeamId", { params: { teamId: id } })
        .then((response) => {
            //console.log(response.data.Message);
            return response.data;
        });
};

const GetNonTeamMembersList = (id) => {
    return axios
        .get(API_URL + "GetNonTeamMembersList", { params: { teamId: id } })
        .then((response) => {
            return response.data;
        });
};

const AddNonUserInTeam = (UserId, TeamId) => {
    var nonUserDetails = new FormData();
    nonUserDetails.append("userId", UserId);
    nonUserDetails.append("teamId", TeamId);
    return axios.post(API_URL + "AddNonUserInTeams", nonUserDetails);
};

const UpdateTeamUserRole = (userId, teamId) => {
    return axios.get(API_URL + "UpdateTeamUserRole?" + "userId=" + userId + "&teamId=" + teamId).then((response) => {
        return response.data;
    });
};

const UpdateTeamUserRoleNew = (userId, teamRole) => {
    return axios.post(API_URL + "UpdateTeamUserRoleNew",{"userId":userId,"teamRole":teamRole})
    .then((response) => {
        return response.data;
    });
};


const ReamoveUserById = (id) => {
    axios.get(API_URL + "ReamoveUserById", { params: { userId: id } }).then(response => {
        console.log(response);
    });
};



export default {
    GetTeamUsersListByTeamId, GetNonTeamMembersList, AddNonUserInTeam, UpdateTeamUserRole,UpdateTeamUserRoleNew, ReamoveUserById
};