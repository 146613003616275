import React, { useEffect, useState, useRef } from "react";

// Services
import assessmentService from "../../../services/assessment.service.js";
import AuthService from "../../../services/auth.service.js";

// Components
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Row
} from "reactstrap";
import { Filter, Settings } from "react-feather";
import { Button, Modal } from "react-bootstrap";
import { setInterval } from "timers";

// Assets
import Watch from "../../../assets/img/icons/watch.png";

/*
* Re submitting Questions View
*
* This view will be displayed if the user
* wishes to resubmit any of the questions answers from the
* review screen
*
* TODO: Combine Re Submit question option with the non time based version
* TODO: can be improved to merge with review view
*
* */
const QuizView = (props) => {

    sessionStorage.removeItem("AssessmentQuizKey");
    sessionStorage.removeItem("ReviewAssessmentKey");
    var currentUser = AuthService.getCurrentUser();
    if (currentUser.roleName === "super-admin") {
        props.history.push("/dashboard/default");
        window.location.reload();
    }
    if (currentUser == null || currentUser == undefined) {
        props.history.push("/");
        window.location.reload();
    }
    var userId = currentUser.id;
    if (props.history.action == "POP") {
        var isKeyPresent = (JSON.parse(sessionStorage.getItem("ReSubmitQuestionKey")));
        if (isKeyPresent == null) {
            props.history.push({
                pathname: '/Assessments'
            })
            window.location.reload();
        }
    }
    if (props.location.state == null || props.location.state == undefined) {
        props.history.push({
            pathname: '/Assessments'
        })
        window.location.reload();
    }
    var CurrentQuestion = props.location.state.question;

    var OptionsWithScore = props.location.state.optionList;
    var currentCounter = props.location.state.currentCounter;
    var checkedRadio = props.location.state.selectedAnswer;
    var AssessmentName = props.location.state.AssessmentName;
    var assessmentId = props.location.state.assessmentId;
    var title = props.location.state.title;
    var TitleList = props.location.state.questions;
    var creditId = props.location.state.creditId;
    var did = 0;

    //for tab view
    const [toggleState, setToggleState] = useState(1);
    //const [AssessmentName, setAssessmentName] = useState(null);
    const [datetimeFromServerForProgress, setDateTimeFromServerForProgress] = useState(null);
    const [showTimesUp, setShowTimesUp] = useState(false);
    //const questions = assessmentService.GetAssessments();
    const [checkedRadioButton, setCheckedRadioButton] = useState(checkedRadio);
    const [seconds, setSecondsForTimer] = useState(null);
    const [minus, setMinus] = useState(null);
    let intervalRef = useRef();
    let localTimeReSubmit = 0;
    const toggleTab = (index) => {
        setToggleState(index);
    };
    const SetTimeForTimer = () => {
        assessmentService.GetPendingTimeForTimer(userId, assessmentId, creditId).then(
            (response) => {
                if (response !== null) {
                    if (response === 0) {
                        //clearInterval(interval.current);
                        setShowTimesUp(true);
                        setTimeout(function () {
                            setShowTimesUp(false);
                            props.history.push({
                                pathname: '/Assessments'
                            })
                            window.location.reload();
                        }, 2000);
                    }else if(response === 'nontimer'){
                        setSecondsForTimer(secondsToMinutesFormatting(0));
                        sessionStorage.setItem("hourAssessment",'nontimer');
                    }else if(response === ''){
                        alert('Please try again.');
                        sessionStorage.removeItem("hourAssessment");
                        sessionStorage.removeItem("DateTimeServer");
                        props.history.push({
                            pathname: "/Assessments",
                        });
                        window.location.reload();
                    } else {

                        var timeForStorage = sessionStorage.getItem("hourAssessment")!=='nontimer' ? Number(JSON.parse(sessionStorage.getItem("hourAssessment"))):'nontimer';
                        if (timeForStorage != 0) {
                            setSecondsForTimer(secondsToMinutesFormatting(timeForStorage));
                            localTimeReSubmit = timeForStorage;
                        }else if(timeForStorage == 'nontimer'){
                            setSecondsForTimer(secondsToMinutesFormatting(0));
                            localTimeReSubmit = 0;
                        } else {

                            setSecondsForTimer(secondsToMinutesFormatting(response));
                            localTimeReSubmit = response;

                            sessionStorage.setItem("hourAssessment", JSON.stringify(localTimeReSubmit));
                        }
                    }
                }
            });
    }
    const secondsToMinutesFormatting = (secondsVal) => {
        if (secondsVal < 0) {
            setMinus("-")
            secondsVal = Math.abs(secondsVal);
            var sec_num = parseInt(secondsVal, 10);
            var hours = Math.floor(sec_num / 3600);
            var minutes = Math.floor(sec_num / 60) % 60;
            var seconds = sec_num % 60;

            return [hours, minutes, seconds]
                .map((v,i) => (v < 10 ? "0" + v : v))
                .filter((v, i) =>  v !== "00" || i > 0)
                .join(":");

        }else{
            var sec_num = parseInt(secondsVal, 10);
            var hours = Math.floor(sec_num / 3600);
            var minutes = Math.floor(sec_num / 60) % 60;
            var seconds = sec_num % 60;

            return [hours, minutes, seconds]
                .map((v) => (v < 10 ? "0" + v : v))
                .filter((v, i) => v !== "00" || i > 0)
                .join(":");
        }
    }
    const handleButtonClick = (e) => {

        var currentUser = AuthService.getCurrentUser();
        var userId = currentUser.id;
        assessmentService.GetCurrentDateAndTimeFromServer().then(
            (response) => {
                if (response !== null) {
                    setDateTimeFromServerForProgress(response);
                    assessmentService.AddUserAssessmentProgress(userId, assessmentId, "0", 0, checkedRadioButton, response, response, creditId).then((response) => {
                        return response;
                    })
                        .then((data) => {
                            assessmentService.GetReviewAnsweredQuestions(userId, assessmentId, creditId).then(
                                (response) => {
                                    if (response !== null) {
                                        let newData = response[0].TitleList.map((sitem,index)=>{
                                            sitem.index = index;
                                            sitem.QuestionList.map((qitem,i)=>{
                                                qitem.index = i;
                                                return qitem;
                                            })
                                            return sitem;
                                        })
                                        response[0].TitleList = newData;
                                        // var time =sessionStorage.getItem("hourAssessment")!=='nontimer' ? Number(JSON.parse(sessionStorage.getItem("hourAssessment"))):'nontimer';
                                        // sessionStorage.setItem("hourAssessment", JSON.stringify(time));
                                        sessionStorage.setItem("FromResubmit", true);
                                        clearInterval(Number(JSON.parse(sessionStorage.getItem("ReviewTimer"))));
                                        clearInterval(Number(JSON.parse(sessionStorage.getItem("ResubmitTimer"))));

                                        sessionStorage.setItem("ReSubmitQuestionKey", "true");
                                        sessionStorage.setItem("AssessmentQuizKey", "false");
                                        sessionStorage.setItem("ReviewAssessmentKey", "false");
                                        sessionStorage.removeItem('assessmentType')

                                        props.history.push({
                                            pathname: '/ReviewAssessment',
                                            state: {
                                                questionsList: response[0],
                                                assessmentId: assessmentId,
                                                creditId: creditId
                                            }

                                        })
                                    }
                                });


                        });
                }
            });
    };
    const SetTimeForTimerReview = () => {
        if (seconds == null) {
            assessmentService.GetPendingTimeForTimer(userId, assessmentId, creditId).then(
                (response) => {
                    if (response !== null) {
                        if (response === 0) {
                            setShowTimesUp(true);
                            setTimeout(function () {
                                setShowTimesUp(false);
                                props.history.push({
                                    pathname: '/Assessments'
                                })
                                window.location.reload();
                            }, 2000);
                        } else if(response === 'nontimer'){
                            setSecondsForTimer(secondsToMinutesFormatting(0));
                            sessionStorage.setItem("hourAssessment",'nontimer');
                        }else if(response === ''){
                            alert('Please try again.');
                            sessionStorage.removeItem("hourAssessment");
                            sessionStorage.removeItem("DateTimeServer");
                            props.history.push({
                                pathname: "/Assessments",
                            });
                            window.location.reload();
                        } else {

                            var timeForStorage = Number(JSON.parse(sessionStorage.getItem("hourAssessment")));
                            if (timeForStorage != 0 && timeForStorage > 0) {
                                setSecondsForTimer(secondsToMinutesFormatting(timeForStorage));
                                localTimeReSubmit = timeForStorage;
                            } else {

                                setSecondsForTimer(secondsToMinutesFormatting(response));
                                localTimeReSubmit = response;
                                sessionStorage.setItem("hourAssessment", JSON.stringify(localTimeReSubmit));
                            }
                        }
                    }
                });
        }
    }

    useEffect(() => {
        //set the values after taking from the session/local storage
        assessmentService.GetCurrentAssessmentSessionValues(userId, assessmentId, creditId).then(
            (response) => {
                if (response !== null) {
                    var timeForStorage = sessionStorage.getItem("hourAssessment")!=='nontimer' ? Number(JSON.parse(sessionStorage.getItem("hourAssessment"))):'nontimer';
                    if (timeForStorage != 0 && timeForStorage > 0) {
                        setSecondsForTimer(secondsToMinutesFormatting(timeForStorage));
                        localTimeReSubmit = timeForStorage;
                    }else if(timeForStorage == "nontimer"){
                        setSecondsForTimer(secondsToMinutesFormatting(0));
                        localTimeReSubmit = 0;
                    } else {
                        setSecondsForTimer(secondsToMinutesFormatting(timeForStorage));
                        localTimeReSubmit = timeForStorage;
                        sessionStorage.setItem("hourAssessment", JSON.stringify(localTimeReSubmit));
                    }
                }
            });


    }, []);

    useEffect(() => {
        const startTimerAgain = () => {
            if (window.location.href.indexOf("ReSubmitQuestionOption") > -1&&sessionStorage.getItem("hourAssessment")!=='nontimer') {
                // if (localTimeReSubmit > 0 && localTimeReSubmit != undefined) {
                    //var test = localTimeReSubmit;
                    //test = localTimeReSubmit - 1;
                    localTimeReSubmit = localTimeReSubmit - 1;
                    setSecondsForTimer(secondsToMinutesFormatting(localTimeReSubmit));
                    sessionStorage.setItem("hourAssessment", JSON.stringify(localTimeReSubmit));
                // } else {
                //     setSecondsForTimer(secondsToMinutesFormatting(0));
                //     localTimeReSubmit = 0;
                //     sessionStorage.removeItem("hourAssessment");
                // }

                if (localTimeReSubmit % 5 == 0 && window.location.href.indexOf("ReSubmitQuestionOption") > -1) {
                    assessmentService.SetPendingTimeForTimer(userId, assessmentId, creditId).then(
                        (response) => {
                            if (response !== null && response != 0) {

                                // var timeForStorage = Number(JSON.parse(sessionStorage.getItem("hourAssessment")));
                                // if (timeForStorage != 0 && timeForStorage > 0) {
                                //     setSecondsForTimer(secondsToMinutesFormatting(timeForStorage));
                                //     localTimeReSubmit = timeForStorage;
                                //     sessionStorage.setItem("hourAssessment", JSON.stringify(localTimeReSubmit));
                                // } else {
                                    setSecondsForTimer(secondsToMinutesFormatting(response));
                                    localTimeReSubmit = response;
                                    sessionStorage.setItem("hourAssessment", JSON.stringify(localTimeReSubmit));

                                // }

                            } else {
                                assessmentService.UpdateUserTestTable(userId, assessmentId, creditId).then(
                                    (response) => {
                                        if (response !== null) {
                                            setSecondsForTimer(secondsToMinutesFormatting(0));
                                            sessionStorage.removeItem("hourAssessment");
                                            localTimeReSubmit = 0;
                                            // clearInterval(interval.current);
                                            setShowTimesUp(true);
                                            setTimeout(function () {
                                                setSecondsForTimer(secondsToMinutesFormatting(0));
                                                sessionStorage.removeItem("hourAssessment");
                                                localTimeReSubmit = 0;
                                                setShowTimesUp(false);
                                                props.history.push({
                                                    pathname: '/Assessments'
                                                })
                                                window.location.reload();
                                            }, 2000);
                                        }
                                    });
                            }
                        });

                }
            }
        }
        intervalRef.current = setInterval(startTimerAgain, 1000);
        sessionStorage.setItem("ResubmitTimer", JSON.stringify(intervalRef.current._id));
        setTimeout(() => {
            document.getElementById("scrollbar1").scrollTop = document.getElementById('answering').offsetTop;
          }, 1000);
        return () => clearInterval(intervalRef.current);
    }, []);

    const ChangescrollTop = () =>{
        document.getElementById("scrollbar1").scrollTop = document.getElementById('answering').offsetTop;
    }

    const onChangeOfCheckedOption = (e) => {
        setCheckedRadioButton(e.target.id);
    }
    return (


        <Row>
            <Modal size="lg" show={showTimesUp} className="modal-colored modal-danger"
                backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Time over!!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Your assessment will now be automatically submitted!!
                </Modal.Body>
            </Modal>
            {/* <div className={sessionStorage.getItem("hourAssessment")!=='nontimer'? 'col-lg-8 col-md-8': 'col-lg-12 col-md-12'}> */}
            <div className='col-lg-8 col-md-8'>
                <Card className="my-card  assessment-wrapper-home">
                    <CardBody className="pb-0">
                        <Row className="align-items-center">
                            <div className="col-lg-6 col-md-6">
                                <div className="float-left">
                                    <h1 className="">
                                        {AssessmentName ? AssessmentName : "Assessment Name"}
                                    </h1>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <div className="float-right">
                                    <h2 className="h3 text-primary font-weight-bold">
                                        {title ? title : "Topic"}
                                    </h2>
                                </div>
                            </div>
                        </Row>
                    </CardBody>

                    <hr className="my-hr" />

                    <CardBody>
                        <Row>
                            <div className="col-lg-1 col-md-1">
                                <div className="question-number rounded-circle text-white font-weight-bold h3">
                                    {currentCounter}
                                </div>
                            </div>


                            <div className="col-lg-11 col-md-11">
                                <h4 className="quiz-question-h5">Question</h4>
                                <p className="textDisable"> {CurrentQuestion}</p>
                            </div>
                        </Row>
                    </CardBody>

                    <hr className="my-hr" />


                    <CardBody className="py-0">
                        <Row>
                            <div className="col-lg-12 col-md-12">
                                <h4>Answer</h4>
                            </div>
                        </Row>
                    </CardBody>
                    <CardBody className="py-0">

                        {OptionsWithScore.map((x,i) => (
                            <div className="option-padd" key={i} >
                                <div className="quiz-options-div">
                                    <div className="form-check" key={x.OptionsId}>
                                        <span className="radio-container">
                                            <input
                                                name="radios-example"
                                                type="radio"
                                                className="form-check-input"
                                                id={x.OptionsId}
                                                value={x.Score}
                                                checked={checkedRadioButton == x.OptionsId}
                                                onChange={onChangeOfCheckedOption}
                                            />
                                            <span className="checkmark"></span>
                                        </span>

                                        <label
                                            title=""
                                            className="form-check-label"
                                            htmlFor={x.OptionsId}
                                        >
                                            {x.Options}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        ))}

                    </CardBody>

                    <CardBody className="text-right">
                        <button id="submit-button" className="quiz-submit-btn btn-pill btn-primary btn col-3"
                            onClick={handleButtonClick}>Submit
                        </button>
                    </CardBody>

                </Card>

            </div>
            <div className="col-lg-4 col-md-4">
            <Card className="my-card">
                {sessionStorage.getItem("assessmentType") !=='nontimer'?
                <>
                
                        <CardHeader className="pb-0">
                            <Row>
                                <div className="col-lg-12 col-md-12">
                                    <Row>
                                        <Col sm={4} md={4}>
                                            <img
                                                src={Watch}
                                                className="img-responsive mt-2 mx-auto d-block"
                                                width="80"
                                                height="80"
                                            />
                                        </Col>
                                        <Col sm={8} md={8}>
                                            <div>
                                                <p className="mb-0">Your time left</p>
                                                <h3 className="display-4 font-weight-bold text-dark" style={{fontSize:"2.9rem"}}>
                                                    {minus}{seconds}
                                                </h3>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Row>
                        </CardHeader>
                        <hr className="my-hr" />
                </>:<div style={{marginTop: "2rem",width:'100%'}}></div>}
                        <div className="scrollbar1" id="scrollbar1" style={{overflow: 'scroll'}}>
                            {sessionStorage.getItem("assessmentType") !=='nontimer' &&  
                            <>
                                <CardBody
                                    className="py-0"
                                    style={{
                                        minHeight: "auto",
                                    }}
                                >
                                    <Row>
                                        <div className="h1 font-weight-bold text-dark text-center px-2" style={{width:'100%',fontSize:'1.75rem'}}>
                                        THIS IS A <span style={{color:'red'}}>TIMED TASK!</span>
                                        </div>
                                    </Row>
                                </CardBody>
                                <hr className="my-hr" />
                            </>
                            }
                            <div className="py-0 text-center">
                                <div className="h1 font-weight-bold text-dark px-2">
                                    Your Assessment Progress
                                </div>
                            </div>
                            <hr className="my-hr" />
                            <CardBody className="py-0 scrollbar1"  style={{overflowY: "scroll"}}>
                                <Row>
                                    <Col md={12} sm={12}>
                                        {/*sidebar*/}
                                        <div className="my-sidenav">
                                            {/*  */}
                                            <div>
                                                {TitleList.TitleList.map((x,index) => (
                                                    
                                                    <div key={index}>
                                                        {title == x.TitleName&&(
                                                            <div style={{display: "none"}}>{(did+=1)}</div>
                                                        )}
                                                        <div className={(title == x.TitleName
                                                                        ? "question-list-title-ative border border-primary bg-primary text-white "
                                                                        : "") +
                                                                    "right-sidebar-h5 pl-2"}
                                                            id={(title == x.TitleName?"answering":'')}
                                                            style={{borderRadius:'0.3rem',display: 'flex',alignItems: 'center',justifyContent: 'space-between'}}
                                                        ><div style={{width:'83%',display:'inline-block'}}>{x.TitleName}</div><span className="pr-1 float-right">100%</span></div>
                                                    {title == x.TitleName && <Card className="my-question-card mb-2 pl-1 pt-2 shadow-none" style={{fontSize:'0.9rem'}}>
                                                            {x.TitleDescription}
                                                        </Card>}
                                                        <hr className="my-hr" />
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </div>
                </Card>
            </div>
           
           
        </Row>

    );
}

const ReSubmitQuestionOption = (props) => (
    <>
        <QuizView {...props} />
    </>
);


export default ReSubmitQuestionOption;

