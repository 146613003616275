import axios from "axios";

const API_URL = process.env.REACT_APP_USER_API_URL;


const GetUserLearningPlansList = (userId) => {
     
    return axios
    .post(API_URL + "GetUserLearningPlansList",{"userId":userId})
    .then((response) => {
        return response.data;
    });
};



export default {
    GetUserLearningPlansList
};
