import React, { useEffect, useState } from "react";

// Services
import shopService from "../../../services/shop.service.js";
import AuthService from "../../../services/auth.service.js";
import assessmentService from "../../../services/assessment.service";
// Components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Table,
  Row,
  CardLink,
  CardText,
  Container,
  FormGroup,
  Input,
  Spinner
} from "reactstrap";
import { Filter, Settings } from "react-feather";
import { Button, Alert, Modal } from "react-bootstrap";
import { toast } from 'react-toastify';

// Icons
import {
  faStopwatch,
  faTag,
  faStar,
  faShoppingCart,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Assets
import Watch from "../../../assets/img/icons/watch.png";
import Stopwatch from "../../../assets/img/icons/Group 729.svg";
import Tag from "../../../assets/img/icons/Group 728.svg";
import ScreenIcon from "../../../assets/img/icons/screen.svg";
import Bulb from "../../../assets/img/icons/bulb.png";
import Wheel from "../../../assets/img/icons/wheel.png";
import CartImg from "../../../assets/img/icons/cart-icon.png";
import "../style/TabView.css";
import 'react-toastify/dist/ReactToastify.css';

toast.configure()

/*
* Shop View
*
* The following view shows the assessments
* that can be purchased
*
* Implemented:
* Buy Courses, which allows partners & company managers
* to purchase assessments that are allocated to them using credits
*
* Following needs to be implemented:
* TODO: Shop Assessment categories
* TODO: Shop Assessment Cart + Payment Gateway
* TODO: Shop Assessment Invoices
* */
const ShopView = (props) => {
  var currentUser = AuthService.getCurrentUser();
  if (currentUser == null || currentUser == undefined) {
    props.history.push("/");
    window.location.reload();
  }

  //for tab view
  const [toggleState, setToggleState] = useState(1);

  //for ratting
  const [rating, setRating] = useState(null);
  const [hover, setHover] = useState(null);


  //for popover
  const directions = [
    {
      name: "Top",
      value: "top",
    },
  ];

  // Catch Rating value
  const handleRating = (rate) => {
    setRating(rate);
    // Some logic
  };

  const [courseDetails, setCourseDetails] = useState([]);
  const [numberOfAssessmentSignup, setNumberOfAssessmentSignup] = useState([]);
  const [courseId, setCourseId] = useState("");
  const [messageShopAssessment, setMessageShopAssessment] = useState(null);
  const [buyAssessmentMessage, setBuyAssessmentMessage] = useState(null);
  const [AssessmentsDatas,setAssessmentsDatas] = useState([]);
  const [Coursescategory,setCoursescategory] = useState("0");
  const [CoursesDetailDatas,setCoursesDetailDatas] = useState([]);
  const [loadingcontroller,setloadingcontroller] = useState(true);

  const toggleTab = (index,type) => {
    if(type){
      //获取Course详情数据
      setloadingcontroller(true);
      setToggleState(index);
      assessmentService.GetAiAssessmentList(currentUser.company_id,type)
      .then((data) => {
        setCoursesDetailDatas(data)
        
        setloadingcontroller(false);
      })
      .catch((e) => {
        console.log(e);
        alert('服务器返回错误！')
      });
    }else{
      setToggleState(index);
    }
  };

  // Initial load
  useEffect(() => {
    var currentUserId = currentUser.id;
    var companyId = currentUser.company_id
     //获取Course Categories数据
    assessmentService.GetAiAssessmentCategoryList(companyId)
    .then((data) => {
      setAssessmentsDatas(data)
      console.log(AssessmentsDatas);
    })
    .catch((e) => {
      console.log(e);
    });

    const coursesDetails = () => {
      var courseDetailList = shopService.GetAllCourseDetails(currentUserId);
      var details = courseDetailList
        .then((response) => {
          return response;
        })
        .then((data) => {
          setCourseDetails(data);
        })
        .catch((e) => {
          console.log(e);
        });
    };
    coursesDetails();
  }, []);

  const onChangeNumberOfAssessmentSignup = (e, id) => {
    setCourseId(id);
    var assessmentNum = [];
    var result = [...courseDetails]; //<- copy course into result
    result = result.map((x) => {
      //<- use map on result to find element to update using id
      if (x.AssessmentId === id) x.numOfAssessment = e.target.value;
      return x;
    });
    assessmentNum = result;

    setCourseDetails(assessmentNum); //<- update ass no with value edited
  };

  const CategoriesDetail = (categoryId) => {
    toggleTab(2,categoryId)
  }
  const buyNowClick = (id) => {

        var currentUserId = currentUser.id;
        setCourseId(id);
        console.log(courseId);
        var NoOfAssessment = [...courseDetails];
        NoOfAssessment = NoOfAssessment.map((x) => {
            if (x.AssessmentId === id) {
                if (x.numOfAssessment.length > 0 && courseId != "") {
                    shopService
                        .BuyCourses(x.numOfAssessment, courseId, currentUserId)
                        .then((response) => {
                            if (response.data.Status === "Success") {
                                toast.success("Congratulations for Buying new Assessment  !!", { position: toast.POSITION.TOP_CENTER })
                                //setAssessmentModalshow(false);
                            } else {
                                toast.success(response.data.Message, { position: toast.POSITION.TOP_CENTER })
                            }
                        });
                } else {
                    //setBuyAssessmentMessage("Please fill number of assessment !!");
                    toast.error('Please fill number of assessment !!', { position: toast.POSITION.TOP_CENTER })
                }
            }
        });
    };

  return (
    <>
      <div className="bloc-Mytabs mb-4" style={{display: 'inline-block'}}>
        <button
          className={toggleState === 1 ? "Mytabs active-Mytabs" : "Mytabs"}
          onClick={() => toggleTab(1)}
        >
          Course Categories
        </button>
        <button
          className={toggleState === 2 ? "Mytabs active-Mytabs" : "Mytabs"}
          onClick={() => toggleTab(2,"0")}
        >
          Buy Courses
        </button>
        {/* <button
          className={toggleState === 3 ? "Mytabs active-Mytabs" : "Mytabs"}
          onClick={() => toggleTab(3)}
        >
          Cart
        </button> */}
        <button
          className={toggleState === 3 ? "Mytabs active-Mytabs" : "Mytabs"}
          onClick={() => toggleTab(3)}
        >
          Invoices
        </button>
      </div>
      <div className="content-Mytabs">
        <div
          className={
            (toggleState === 4 ? "content-t  active-content-t" : "content-t") +
            " bg-transparent"
          }
        >
          {/*......................................Buy Courses Tab.......................................*/}

          <Row>
            {courseDetails.map((schema) => {
              return (
                <div className="col-lg-4 col-md-6" key={schema.AssessmentId}>
                  <Card className="shop-assessment-card">
                    <CardHeader>
                      <Row className="align-items-center">
                        <div className="col-lg-4 col-md-6">
                          <img
                            src={Watch}
                            className="img-responsive mt-2"
                            width="50"
                            height="50"
                          />
                        </div>
                        <div className="col-lg-8 col-md-12">
                          <h3>{schema.AssessmentName}</h3>
                        </div>
                      </Row>
                    </CardHeader>
                    <hr className="my-hr" />
                    <CardBody>
                      <Row className="align-items-center">
                        <div className="col-lg-6 col-md-6">
                          <div className="media align-items-center">
                            <FontAwesomeIcon
                              style={{ color: "#3B82EC" }}
                              icon={faStopwatch}
                              fixedWidth
                              size={"2x"}
                              className="mr-2"
                            />
                            <div className="media-body">
                              <p className="small mb-0">Hours Required</p>
                              <p className="font-weight-bold mb-0">12 Hours</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="media align-items-center">
                            <FontAwesomeIcon
                              style={{ color: "#3B82EC" }}
                              icon={faTag}
                              fixedWidth
                              size={"2x"}
                              className="mr-2"
                            />
                            <div className="media-body">
                              <p className="small mb-0">Total Cost</p>
                              <p className="font-weight-bold mb-0">
                                $ {schema.Default_Credit}
                              </p>
                            </div>
                          </div>
                        </div>
                      </Row>
                    </CardBody>
                    <hr className="my-hr" />
                    <CardBody>
                      <p>
                        Course Description.Lorem Ipsum is simply dummy text of
                        the printing and typesetting industry.
                      </p>

                      <FormGroup>
                        <Input
                          bsSize="lg"
                          type="number"
                          name={schema.AssessmentId}
                          placeholder="Number of Assessment"
                          onChange={(e) =>
                            onChangeNumberOfAssessmentSignup(
                              e,
                              schema.AssessmentId
                            )
                          }
                          value={schema.numOfAssessment}
                        />
                      </FormGroup>
                    </CardBody>
                    <hr />

                    <CardBody>
                      <div className="float-left">
                        <a href="#">More info.</a>
                      </div>
                      <Button
                        color="primary"
                        className="float-right BuyNow-btn"
                        onClick={() => buyNowClick(schema.AssessmentId)}
                      >
                        Buy Now{" "}
                        <FontAwesomeIcon
                          style={{ color: "#ffffff" }}
                          icon={faShoppingCart}
                        />{" "}
                      </Button>
                    </CardBody>
                  </Card>
                </div>
              );
            })}

            {buyAssessmentMessage && (
              <Alert variant="danger">{buyAssessmentMessage}</Alert>
            )}

          </Row>
        </div>

        {messageShopAssessment && (
          <Alert variant="success" style={{ width: "400px" }}>
            {messageShopAssessment}
          </Alert>
        )}

        {/*...................................Course Categoris Tab...................................*/}
        <div
          className={
            toggleState === 1 ? "content-t  active-content-t" : "content-t"
          }
        >
          <Card>
            <CardBody>
              <h2 className="mb-3">Assessments Categories</h2>

              <Row style={{ margin: "0 1.4rem"}}>
                {
                  AssessmentsDatas.map(function(item,index) {
                    return (
                      <div className="col-lg-6 col-md-6" style={{ }} key={index}>
                        <Card className="my-card shadow-lg assessment-course-cat" style={{}}>
                          <CardHeader style={{ padding: "0.5rem 0.7rem", minHeight:"6rem"}}>
                            <Row className="align-items-center">
                              <div className="" style={{ marginRight:"1.7rem"}}>
                                <img className="img-responsive mt-2" src={item.category_icon}/>
                              </div>
                              <div className="" style={{ width: "18rem"}}>
                                <h3 style={{fontWeight:"500",color:"#343A40"}}>
                                {item.category_name}
                                </h3>
                              </div>
                            </Row>
                          </CardHeader>
                          <hr className=""/>
                          <CardBody style={{padding:"0.25rem"}}>
                            <p style={{ height:"4.5rem",overflowY: "scroll"}}>
                              {item.category_description}
                            </p>
                            <Button color="primary" className="float-left" onClick={() => CategoriesDetail(item.id)}>
                              View
                            </Button>
                          </CardBody>
                        </Card>
                      </div>
                    )
                  })
                }
              </Row>
            </CardBody>
          </Card>
        </div>

        {/*.........................Cart Tab.....................*/}
        <div
          className={
            (toggleState === 2 ? "content-t  active-content-t" : "content-t") +
            " p-0 bg-transparent"
          }
        >
          <Row>      
            <div className="col-lg-12 col-md-12">
              {/*.........................Assessment Lists.....................*/}

              <Row>
              {loadingcontroller && (<div className="loading" >
                <Row className="justify-content-center align-self-center w-100 text-center" style={{height: "100%",alignItems: "center"}}>
                    <Spinner color="primary" />
                  </Row>
                </div>)}
                {
                  CoursesDetailDatas.map(function(item,index){
                    return(
                      <div className="col-lg-4 col-md-4" key={index}>
                        <Card className="shop-assessment-card">
                          <CardHeader>
                            <Row>
                              <div className="" style={{marginRight:"1.7rem"}}>
                                <img
                                  src={item.assessment_icon}
                                  className="img-responsive mt-2"
                                  width="50"
                                  height="50"
                                />
                              </div>
                              <div className="" style={{width: "12rem",paddingTop:"1rem"}}>
                                <h3>
                                  {item.assessment_name}
                                </h3>
                                <div>
                                  {[...Array(5)].map((star, index) => {
                                    const ratingValue = index + 1;
                                    return (
                                      <label key={index}>
                                        <input
                                          
                                          type="radio"
                                          style={{display:"none"}}
                                          name="rating"
                                          value={ratingValue}
                                          // onClick={() => setRating(ratingValue)}
                                        />
                                        <FontAwesomeIcon
                                          className="star"
                                          color={
                                            ratingValue <= item.assessment_rating
                                              ? "#3B82EC"
                                              : "#e4e5e9"
                                          }
                                          icon={faStar}
                                          // onMouseEnter={() => setHover(ratingValue)}
                                          // onMouseLeave={() => setHover(null)}
                                          fixedWidth
                                        />
                                      </label>
                                    );
                                  })}
                                </div>
                              </div>
                            </Row>
                          </CardHeader>
                          <hr className="my-hr" /> 
                          <CardBody className="px-0" style={{minHeight:"5.2rem"}}>
                            <Row>
                              <div className="col-lg-6 col-md-6">
                                <div className="media align-items-center" style={{alignItems: "flex-start !important"}}>
                                <div className="iconContent">
                                  <div style={{height:"0.4rem"}}></div>
                                  <img className="icon1" src={Stopwatch}/>
                                </div>
                                  <div className="media-body">
                                    <p className="small mb-0">Hours Required</p>
                                    <p className="font-weight-bold mb-0" style={{color: "#000"}}>{item.default_time}</p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-6 col-md-6">
                                <div className="media align-items-center">
                                <div className="iconContent">
                                  <div style={{height:"0.4rem"}}></div>
                                  <img className="icon1" src={Tag}/>
                                </div>
                                  <div className="media-body">
                                    <p className="small mb-0">Total Cost</p>
                                    <p className="font-weight-bold mb-0" style={{color: "#000"}}>C {item.default_credit}</p>
                                  </div>
                                </div>
                              </div>
                            </Row>
                          </CardBody>
                          <hr className="my-hr" />
                          <CardBody className="py-2 px-2">
                            <p className="mb-0" style={{height:"90px",overflowY: "scroll"}}>
                              {item.assessment_description}
                            </p>
                          </CardBody>
                          <hr className="my-hr" />

                          <CardBody className="py-2 px-2" style={{display:"flex",justifyContent: "space-between",alignItems: "center"}}>
                            <div className="float-left">
                              <a href={'http://'+item.information_url} target="_blank" rel="noreferrer">More info...</a>
                            </div>
                            <Button color="primary" className="float-right BuyNow-btn" >
                              {/* Add to cart{" "}
                              <FontAwesomeIcon
                                style={{ color: "#ffffff" }}
                                icon={faShoppingCart}
                              /> */}
                            </Button>
                          </CardBody>
                        </Card>
                      </div>
                    )
                  })
                }
              </Row>
            </div>
            {/* <div className="col-lg-4 col-md-4"> */}
              {/*.........................After Added into cart.....................*/}
              {/* <Card className="my-card parent-gray_card bg-dark">
                <CardHeader className="parent-gray_card bg-transparent">
                  <Row className="align-items-center">
                    <div className="col-lg-4 col-md-6">
                      <img
                        src={CartImg}
                        className="img-responsive mt-2"
                        width="50"
                        height="50"
                      />
                    </div>
                    <div className="col-lg-8 col-md-12">
                      <h3 className="text-white mb-0 font-weight-bolder">
                        Your Shopping <br />
                        Cart
                      </h3>
                    </div>
                  </Row>
                </CardHeader>
                <hr className="my-hr" />
                <CardBody>
                  <Row>
                    <div className="col-lg-6 col-md-6">
                      <div className="media align-items-center">
                        <FontAwesomeIcon
                          style={{ color: "#3B82EC" }}
                          icon={faTag}
                          fixedWidth
                          size={"2x"}
                          className="mr-2"
                        />
                        <div className="media-body">
                          <p className="small mb-0">Total</p>
                          <p className="font-weight-bolder mb-0 text-white h4">
                            $1800
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <p className="card-font">Price are in AUD</p>
                    </div>
                  </Row>
                </CardBody>
                <hr className="my-hr" />
                <CardBody>
                  <p className="card-font">You have 2 courses in your cart</p>
                  <Row>
                    <div className="col-lg-12 col-md-6">
                      <CardHeader className="light-gray_card">
                        <Row>
                          <div className="col-lg-4 col-md-6">
                            <img
                              src={Watch}
                              className="img-responsive mt-2 white-img"
                              width="50"
                              height="50"
                            />
                          </div>
                          <div className="col-lg-8 col-md-12">
                            <h3 className="card-font">
                              Assessment <br />
                              Name 3
                            </h3>
                            <div>
                              <h6 className="card-font">
                                <span>
                                  <FontAwesomeIcon
                                    style={{ color: "#3B82EC" }}
                                    icon={faTag}
                                    fixedWidth
                                  />
                                </span>
                                Total cost
                              </h6>
                              <h6 className="card-font card-space-left ">
                                {" "}
                                $900
                              </h6>
                            </div>
                            <div className="float-right BuyNow-btn card-font">
                              <a href="#">Remove</a>
                            </div>
                          </div>
                        </Row>
                      </CardHeader>
                    </div>

                    <div className="col-lg-12 col-md-6">
                      <br />
                      <CardHeader className="light-gray_card">
                        <Row>
                          <div className="col-lg-4 col-md-6">
                            <img
                              src={Watch}
                              className="img-responsive mt-2"
                              width="50"
                              height="50"
                            />
                          </div>
                          <div className="col-lg-8 col-md-12">
                            <h3 className="card-font">
                              Assessment <br />
                              Name 4
                            </h3>
                            <div>
                              <h6 className="card-font">
                                <span>
                                  <FontAwesomeIcon
                                    style={{ color: "#3B82EC" }}
                                    icon={faTag}
                                    fixedWidth
                                  />
                                </span>
                                Total cost
                              </h6>
                              <h6 className="card-font card-space-left ">
                                {" "}
                                $900
                              </h6>
                            </div>
                            <div className="float-right BuyNow-btn card-font">
                              <a href="#">Remove</a>
                            </div>
                          </div>
                        </Row>
                      </CardHeader>
                    </div>
                  </Row>
                </CardBody>
                <hr className="my-hr" />

                <CardBody>
                  <Button color="primary" className="float-right BuyNow-btn">
                    Checkout{" "}
                  </Button>
                </CardBody>
              </Card>
            </div> */}
          </Row>
        </div>

        {/*.........................Invoices Tab.....................*/}
        <div
          className={
            toggleState === 3 ? "content-t  active-content-t" : "content-t"
          }
        >
          <Card>
            <CardBody>
              <h2>Invoices</h2>

              <Table striped hover>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Invoice Number</th>
                    <th>Price</th>
                    <th>Download</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>01/01/0001</td>
                    <td>123456</td>
                    <td>2500</td>
                    <td>
                      <FontAwesomeIcon
                        style={{ color: "#3B82EC" }}
                        icon={faDownload}
                        fixedWidth
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>01/01/0001</td>
                    <td>123456</td>
                    <td>2500</td>
                    <td>
                      <FontAwesomeIcon
                        style={{ color: "#3B82EC" }}
                        icon={faDownload}
                        fixedWidth
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>01/01/0001</td>
                    <td>123456</td>
                    <td>2500</td>
                    <td>
                      <FontAwesomeIcon
                        style={{ color: "#3B82EC" }}
                        icon={faDownload}
                        fixedWidth
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>01/01/0001</td>
                    <td>123456</td>
                    <td>2500</td>
                    <td>
                      <FontAwesomeIcon
                        style={{ color: "#3B82EC" }}
                        icon={faDownload}
                        fixedWidth
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>01/01/0001</td>
                    <td>123456</td>
                    <td>2500</td>
                    <td>
                      <FontAwesomeIcon
                        style={{ color: "#3B82EC" }}
                        icon={faDownload}
                        fixedWidth
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
};

const SgaShop = () => (
  <div id="shop_div" style={{paddingLeft:"1.2rem"}}>
    <Row className="mb-2 mb-xl-4" style={{position: "absolute", right: "2.5rem"}}>
      <Col xs="auto" className="ml-auto text-right mt-n1">
        <Button color="primary" className="shadow-sm mr-1 screenicon">
        </Button>
        {/* <Button color="primary" className="shadow-sm">
          <Settings className="feather" />
        </Button> */}
      </Col>
    </Row>
    <ShopView />
  </div>
);

export default SgaShop;
