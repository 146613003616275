import React, { useState , useEffect} from "react";
import { connect } from "react-redux";
import { Link ,useLocation} from "react-router-dom";
import store from '../redux/store'
import { toggleSidebar } from "../redux/actions/sidebarActions";
import companiesService from "../services/companies.service.js";
import {
    Row,
    Col,
    Collapse,
    Navbar,
    Nav,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    ListGroup,
    ListGroupItem,
    Form,
    Input
} from "reactstrap";

import {
    AlertCircle,
    Bell,
    BellOff,
    Home,
    Settings,
    User,
    Lock,
    UserPlus
} from "react-feather";

import usFlag from "../assets/img/flags/us.png";
import esFlag from "../assets/img/flags/es.png";
import deFlag from "../assets/img/flags/de.png";
import nlFlag from "../assets/img/flags/nl.png";

import avatar1 from "../assets/img/avatars/avatar.png";
import AuthService from "../services/auth.service.js"

// calls when logout button is clicked from dashboard of logged in company/user
const logOutUser = () => {
    AuthService.logoutUser();

};
const logOutCompany = () => {
    AuthService.logoutCompany();

}

const notifications = [
    {
        type: "default",
        title: "Coming Soon...",
        description: "Assessment Notifications are coming soon...",
        // time: "12h ago"
    }
    // {
    //     type: "important",
    //     title: "Update completed",
    //     description: "Restart server 12 to complete the update.",
    //     time: "2h ago"
    // },
    // {
    //     type: "default",
    //     title: "Lorem ipsum",
    //     description: "Aliquam ex eros, imperdiet vulputate hendrerit et.",
    //     time: "6h ago"
    // },
    // {
    //     type: "login",
    //     title: "Login from 192.186.1.1",
    //     description: "",
    //     time: "6h ago"
    // },
    // {
    //     type: "request",
    //     title: "New connection",
    //     description: "Anna accepted your request.",
    //     time: "12h ago"
    // }
];


const NavbarDropdown = ({
    children,
    count,
    showBadge,
    header,
    footer,
    icon: Icon
}) => (
        <UncontrolledDropdown nav inNavbar className="mr-2">
            <DropdownToggle nav className="nav-icon dropdown-toggle">
                <div className="position-relative">
                    <Icon className="align-middle" size={18} />
                    {showBadge ? <span className="indicator">{count}</span> : null}
                </div>
            </DropdownToggle>
            <DropdownMenu right className="dropdown-menu-lg py-0">
                <div className="dropdown-menu-header position-relative">
                    {count} {header}
                </div>
                <ListGroup>{children}</ListGroup>
                <DropdownItem header className="dropdown-menu-footer">
                    <span className="text-muted">{footer}</span>
                </DropdownItem>
            </DropdownMenu>
        </UncontrolledDropdown>
    );

const NavbarDropdownItem = ({ icon, title, description, time, spacing }) => (
    <ListGroupItem>
        <Row noGutters className="align-items-center">
            <Col xs={2}>{icon}</Col>
            <Col xs={10} className={spacing ? "pl-2" : null}>
                <div className="text-dark">{title}</div>
                <div className="text-muted small mt-1">{description}</div>
                <div className="text-muted small mt-1">{time}</div>
            </Col>
        </Row>
    </ListGroupItem>
);

const NavbarComponent = (props) => {

    var currentUser = null;
    var companLogoIfo = null;
    var LoginLogo = null;
    let Route = useLocation().pathname
    //var currentCompany = null;
    //check the navbar for the companyid field , if not present all the users will be added to default company else whichever is in query string
    const queryParams = new URLSearchParams(window.location.search);
    var currentUser = AuthService.getCurrentUser();
    LoginLogo = AuthService.getLadingCompanyLogo()? AuthService.getLadingCompanyLogo().partnerLogoUrl : '';
    var companLogoIfo = AuthService.getcompanLogoIfo()
    var companyLogoUrl1 = (companLogoIfo!==null && (window.location.pathname === "/companyLogin" ||window.location.pathname === "/thankyou"))?(companLogoIfo.companyLogoUrl?true:false):false;
    var partnerLogoUrl = (companLogoIfo!==null && (window.location.pathname === "/partnerLogin" || window.location.pathname === "/companyLogin"||window.location.pathname === "/thankyou") ) ?(companLogoIfo.partnerLogoUrl?true:false):false;
    var backToLogin = (window.location.pathname === "/partnerLogin" || window.location.pathname === "/companyLogin" || window.location.pathname === "/")?false:true;
    let companyId = queryParams.get('companyId');
    let registeredCompanyId = queryParams.get('cid');
    //if there is no companyid then set to 0
    if (companyId === null) {
        companyId = 0;
    }
    if (registeredCompanyId === null) {
        registeredCompanyId = 0;
    }
    const [route, setRoute] = useState(Route)
    const [currentCompany, setCompanyLogoDetails] = useState("");
    const [registeredCompanyLogo, setRegisteredCompanyLogo] = useState("");
    /*2022-9-30-2022-10-7*/
    const [companyLogoUrl, setCompanyLogoUrl] = useState("");
    
    const urlParams = new URL(window.location.href);
    const pathname = urlParams?.pathname;
    /*end*/
    useEffect(() => {
       if(currentUser){
            AuthService.GetCompanyLogoById(currentUser.company_id).then(
                (response) => {
                    setCompanyLogoUrl(response);
                }
            );
       }
    //    if(pathname==="/landingpage"){
    //         AuthService.GetLoginLogoByCompany(pathname.slice(1)).then(
    //             (response) => {
    //             setLoginLogo(response);
    //             }
    //         );
    //    }
        const detailCompany = () => {

            AuthService.getCompanyDetailsByCompanyId(companyId).then(
                (response) => {
                     
                    if (response !== null) {
                        setCompanyLogoDetails(response)
                    }

                }
            );
        };
        const detailRegCompany = () => {
            if (registeredCompanyId != 0) {
                AuthService.getCompanyDetailsByCompanyId(registeredCompanyId).then(
                    (response) => {
                         
                        if (response !== null) {
                            setRegisteredCompanyLogo(response)
                        }

                    }
                );
            } else {
                setRegisteredCompanyLogo("")
            }
        };

        detailCompany();
        detailRegCompany();
    }, []);
    const toProfile = () => {
        props.history.push({
            pathname: "/userProfile",
            state: {data: currentUser.id, firstName: currentUser.FirstName, lastName: currentUser.LastName},
        });
    }

    let CompanyPageInfo = companiesService.getCompanyPageInfo();
    /*2022-10-8*/
    const loginBtnStyle = {
        'width': '66px',
        'height': '34px',
        'lineHeight': '34px',
        'borderRadius': '4px',
        'backgroundColor': CompanyPageInfo?CompanyPageInfo.colour_1:"#000",
        'textAlign': 'center',
        'color': '#fff',
        'fontSize': '14px',
        'cursor': 'pointer',
        'marginRight':'180px',
    }
    const LanStyle = pathname=="/landingpage"?{
        'marginRight':'80px',
    }:{}
    const leftImgStyle = pathname=="/landingpage"?{
        'margin':'10px 0 0 320px',
    }:{}
    const LoginLogoStyle={
       
    }
    const LoginLogogroupStyle = pathname=="/landingpage"? {
        'display':'flex',
        'justifyContent': "space-around",
        'width':'50%',
        'marginLeft':'16%',
        'alignItems': 'center'
    }:{
        'display':'flex',
        'justifyContent': "space-around",
        'width':'90%',
        'alignItems': 'center'
    }


    /*end*/
    if (currentUser || (currentCompany !== "" && companyId !== null) || (registeredCompanyLogo !== "" && registeredCompanyId!==null)) {
        return (

            <Navbar color="white" light expand>
                {/* {currentUser !== null ?<span
                    className="sidebar-toggle d-flex mr-2"
                    onClick={() => {
                        dispatch(toggleSidebar());
                    }}
                >
                    <i className="hamburger align-self-center" />
                </span>:<div></div>} */}
                {pathname!=="/landingpage"&& currentUser ?<span
                    className="sidebar-toggle d-flex mr-2"
                    onClick={() => {
                        store.dispatch(toggleSidebar());
                    }}
                >
                    <i className="hamburger align-self-center" />
                </span>:''}
                <Form inline>
                    {/* 暂时隐藏 */}
                    {/* {pathname!=="/landingpage"?
                    <Input
                        type="text"
                        placeholder="Search ..."
                        aria-label="Search"
                        className="form-control-no-border mr-sm-2"
                    />:''
                    } */}
                    {/* {currentUser !== null ? <Input
                        type="text"
                        placeholder="Search ..."
                        aria-label="Search"
                        className="form-control-no-border mr-sm-2"
                    />:<div></div>} */}
                </Form>

                <Collapse navbar>
                    {/* {currentCompany === null && currentUser === null ?
                        <div className="logo-left" >
                            <h6>Comprara logo</h6>
                            <p>hide login</p>
                        </div> : <div></div>
                    } */}
                    {/* {currentUser !== null ?<div>
                    </div> : (currentCompany !== null && currentCompany !== "" && currentCompany.logoDetails !== null) ? <div className="logo-center">
                        <h6><img src={'data:' + currentCompany.logoDetails.FileType + ';base64,' + currentCompany.logoDetails.FileContent}
                            alt={"logo"} width="185px" style={leftImgStyle}
                            height="58px" /> </h6>
                    </div> : <div>
                    </div>} */}
                    {/* {currentUser !== null || registeredCompanyId==0 ? <div>
                    </div> : (registeredCompanyLogo !== null && registeredCompanyLogo !== "" && registeredCompanyLogo.logoDetails !== null) ? <div className="logo-company-domain">
                            <h6><img src={'data:' + registeredCompanyLogo.logoDetails.FileType + ';base64,' + registeredCompanyLogo.logoDetails.FileContent}
                            alt={"logo"} width="185px"
                            height="58px" /> </h6>
                    </div> : <div>
                    </div>} */}
                   
                    
                    <div style={LoginLogogroupStyle}>
                        {currentUser === null &&
                            <div className="logo-center">
                                <Link to='/'>
                                        <img src={require('../assets/img/logo/skillsgaplogo.png').default}
                                        alt={"logo"} width="185px"
                                         />
                                </Link>
                            </div>
                        } 
                        {(companyLogoUrl!==""&& currentUser !== null )&&
                            <div>
                                <img className="logo-center" style={{maxHeight:"60px"}} src={companyLogoUrl}  />
                            </div>
                        }

                        {(LoginLogo!==null &&  pathname==="/landingpage")&&
                            <div style={{width:"50%"}}>
                                <img className="logo-center" src={LoginLogo}  style = {LoginLogoStyle} />
                            </div>
                        }

                        

                        {companyLogoUrl1  && 
                            (<div className="logo-company-domain">
                                    <Link to={'/' + companLogoIfo.companyPageUrl}>
                                            <img src={companLogoIfo.companyLogoUrl}
                                            alt={"logo"} width="185px"
                                             />
                                    </Link>
                            </div>)
                        }

                        {partnerLogoUrl &&
                            (<div className="logo-company-domain">
                                    <Link to={'/' + companLogoIfo.partnerPageUrl + '/login'}>
                                            <img src={companLogoIfo.partnerLogoUrl}
                                            alt={"logo"} width="185px"
                                             />
                                    </Link>
                            </div>)
                        }
                    </div>

                    <Nav className="ml-auto" navbar style={{ marginRight:"5%"}}>
                    {(pathname =="/AssessmentQuiz" ||pathname =='/ReviewAssessment'||pathname =='/ReSubmitQuestionOption'||pathname =='/ReSubmitQuestionOptionNonTimer') && (<div className="masking" style={{position: "absolute",inset: "0px",zIndex: "99"}}></div>)}
                        {/* 暂时隐藏（通知和语言功能按钮）
                        {(currentUser || currentCompany)&&pathname!="/landingpage" ? <NavbarDropdown
                            header="New Notifications"
                            footer="Show all notifications"
                            icon={Bell}
                            count={notifications.length}
                        >
                            {notifications.map((item, key) => {
                                let icon = <Bell size={18} className="text-warning" />;

                                if (item.type === "important") {
                                    icon = <AlertCircle size={18} className="text-danger" />;
                                }

                                if (item.type === "login") {
                                    icon = <Home size={18} className="text-primary" />;
                                }

                                if (item.type === "request") {
                                    icon = <UserPlus size={18} className="text-success" />;
                                }

                                return (
                                    <NavbarDropdownItem
                                        key={key}
                                        icon={icon}
                                        title={item.title}
                                        description={item.description}
                                        time={item.time}
                                    />
                                );
                            })}
                        </NavbarDropdown> : <div></div>
                        }

                        <UncontrolledDropdown nav inNavbar className="mr-2">
                            <DropdownToggle nav caret className="nav-flag" style={LanStyle}>
                                <img src={usFlag} alt="English" />
                            </DropdownToggle>
                            <DropdownMenu right>
                                <DropdownItem>
                                    <img
                                        src={usFlag}
                                        alt="English"
                                        width="20"
                                        className="align-middle mr-1"
                                    />
                                    <span className="align-middle">English</span>
                                </DropdownItem>
                                <DropdownItem>
                                    <img
                                        src={esFlag}
                                        alt="Spanish"
                                        width="20"
                                        className="align-middle mr-1"
                                    />
                                    <span className="align-middle">Spanish</span>
                                </DropdownItem>
                                <DropdownItem>
                                    <img
                                        src={deFlag}
                                        alt="German"
                                        width="20"
                                        className="align-middle mr-1"
                                    />
                                    <span className="align-middle">German</span>
                                </DropdownItem>
                                <DropdownItem>
                                    <img
                                        src={nlFlag}
                                        alt="Dutch"
                                        width="20"
                                        className="align-middle mr-1"
                                    />
                                    <span className="align-middle">Dutch</span>
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown> */}
                        {
                            pathname!="/landingpage" ?"":
                            <Link to={'/'+CompanyPageInfo.company_page_url+'/login'}>
                                <div style={loginBtnStyle}>
                                Login
                                </div>
                            </Link>
                        }
                        {currentUser || (currentCompany && currentCompany.CompanyName) ? <UncontrolledDropdown nav inNavbar>
                            <span className="d-inline-block d-sm-none">
                                <DropdownToggle nav caret>
                                    <Settings size={18} className="align-middle" />
                                </DropdownToggle>
                            </span>
                            <span className="d-none d-sm-inline-block">
                                <DropdownToggle nav caret>
                                    <img
                                        src={avatar1}
                                        className="avatar img-fluid rounded-circle mr-1"
                                        alt="Chris Wood"
                                    />
                                    <span className="text-dark">{currentUser !== null && currentUser.Email ? currentUser.FirstName : currentCompany = null ? currentCompany.CompanyName : ""}</span>
                                </DropdownToggle>
                            </span>
                            <DropdownMenu right>
                                
                                <DropdownItem >
                                    <Link to={{pathname:'/userProfile',state: {data: currentUser.id, firstName: currentUser.FirstName, lastName: currentUser.LastName}}}  className="nav-link" >
                                        <User size={18} className="align-middle mr-2" />
                                        Profile
                                    </Link>
                                </DropdownItem>
                                <DropdownItem divider />

                                {currentUser === null ? <DropdownItem><a href="/" className="nav-link" onClick={logOutCompany}>
                                    <Lock size={18} className="align-middle mr-2" />
                                    LogOut
                                </a></DropdownItem> : <DropdownItem><a href="/" className="nav-link" onClick={logOutUser}>
                                    <Lock size={18} className="align-middle mr-2" />
                                    LogOut
                                </a></DropdownItem>}
                            </DropdownMenu>
                        </UncontrolledDropdown> : <div></div>
                        }
                    </Nav>
                </Collapse>
            </Navbar>
        );
    }
    else {
        return (<Navbar color="white" light expand></Navbar>)
    }
};

export default connect(store => ({
    app: store.app
}))(NavbarComponent);
