import React, {useState, useRef, useEffect} from "react";

// Services
import AuthService from "../../services/auth.service.js";


// Components
import {
    Button,
    Card,
    CardBody,
    Form,
    FormGroup,
    Label,
    Alert,
    Input,
    CardHeader,
    Col
} from "reactstrap";
import {  Check, X } from "react-feather";
import { Popover } from 'antd';
import "./css/ResetPassword.css"
import CoverIMG from "../../assets/img/photos/CoverIMG.jpg"
import styles from "./css/Login.module.css";
/*
* Password Reset
*
* Resetting password will happen if the auth code is present on the URL
* An email to the user will be sent with the URL + auth code
*
* TODO: Needs to be tested
* */

const ChangePassword = (props) => {
    const queryParams = new URLSearchParams(window.location.search);
    let ViewId = queryParams.get('AuthId') ;
    let resetcode = queryParams.get('ResetCode');
    let resetcode1 = (sessionStorage.getItem("resetcode"));
    let resetcode2 = (sessionStorage.getItem("password_reset_mandatory"))
    if (resetcode == null && resetcode1 == null && resetcode2 == null){
        props.history.push({
            pathname: '/'
        })
        window.location.reload();
    }else{
        if(sessionStorage.getItem("resetcode")){
            ViewId = sessionStorage.getItem("ViewId");
            resetcode = resetcode1;
        }
    }

    const [title, setTitle] = useState("Change Password");
    const [newPasswordForReset, setNewPasswordForReset] = useState("");
    const [confirmPasswordForReset, setConfirmPasswordForReset] = useState("");
    const [messagePassReset, setMessagePassReset] = useState("");
    const [viewId, setviewId] = useState("");
    const [Resetcode, setResetcode] = useState("");
    const [modal, setmodal] = useState(false);
    const [colors, setcolors] = useState("info");
    //password rule
    const [PasspasswordRule1, setPasspasswordRule1] = useState(false);
    const [PasspasswordRule2, setPasspasswordRule2] = useState(false);
    const [PasspasswordRule3, setPasspasswordRule3] = useState(false);
    const [PasspasswordRule4, setPasspasswordRule4] = useState(false);
    


    useEffect(() => {
        //set the values after taking from the session/local storage
        setviewId(ViewId);
        setResetcode(resetcode)
        if(resetcode2 != '' && resetcode2 !=null){
            setTitle("New Password")
        }
        return()=>{
            sessionStorage.removeItem("resetcode")
            sessionStorage.removeItem("ViewId")
            sessionStorage.removeItem("password_reset_mandatory")
        }
    }, []);

    const ChangePassIfOldPasswordValid = () => {
        if (newPasswordForReset != "" && confirmPasswordForReset != "") {
            if(!PasspasswordRule1 || !PasspasswordRule2 || !PasspasswordRule3|| !PasspasswordRule4){
                setVisible(true)
                setcolors('danger')
                setMessagePassReset("Password is too weak. You must use a stronger password to continue.");
            }else{
                if (newPasswordForReset != confirmPasswordForReset) {
                    setVisible(true)
                    setcolors('danger')
                    setMessagePassReset("The passwords you entered do not match. Please re-enter your password");
                } else {
                    if (viewId != "" && viewId != null) {
                        AuthService.changePasswordIfEmailValid(viewId, newPasswordForReset,Resetcode).then((response) => {
                            if (response.Status === "200") {
                                setviewId("");
                                setVisible(true)
                                setcolors('success')
                                setMessagePassReset("Password change successfully.");
                                setTimeout(function () {
                                    sessionStorage.removeItem("");
                                    props.history.push({
                                        pathname: '/'
                                    })
                                    window.location.reload();
                                }, 5000);
                            } else {
                                setVisible(true)
                                setcolors('danger')
                                setMessagePassReset(response.Message);
                            }
                        });
                    } else if(resetcode2 != '' && resetcode2 !=null){
                        AuthService.ChangePasswordFirstLogin(resetcode2,newPasswordForReset).then((response) => {
                            if (response.Status === "200") {
                                setviewId("");
                                setVisible(true)
                                setcolors('success')
                                setMessagePassReset("Password change successfully.");
                                setTimeout(function () {
                                    sessionStorage.removeItem("");
                                    props.history.push({
                                        pathname: '/userProfile'
                                    })
                                    window.location.reload();
                                }, 5000);
                            } else {
                                setVisible(true)
                                setcolors('danger')
                                setMessagePassReset(response.Message);
                            }
                        });
                    }else {
                        props.history.push("/");
                        window.location.reload();
                    }
                }
            }
        } else {
            setVisible(true)
            setcolors('danger')
            setMessagePassReset("Kindly enter all the fields");
        }
    }
    const [visible, setVisible] = useState(true);
    const onDismiss = () => setVisible(false);

    const onChangeNewPasswordForReset = (e) => {
        let passwordRule1 = /.{8,}/;
        let passwordRule2 = /[A-Z]/;
        let passwordRule3 = /[a-z]/;
        let passwordRule4 = /\d/;
        const password = e.target.value;
        if(passwordRule1.test(password)){
            setPasspasswordRule1(true)
        }else{
            setPasspasswordRule1(false)
        }
        if(passwordRule2.test(password)){
            setPasspasswordRule2(true)
        }else{
            setPasspasswordRule2(false)
        }
        if(passwordRule3.test(password)){
            setPasspasswordRule3(true)
        }else{
            setPasspasswordRule3(false)
        }
        if(passwordRule4.test(password)){
            setPasspasswordRule4(true)
        }else{
            setPasspasswordRule4(false)
        }
        setNewPasswordForReset(password);
    };
    const onChangeConfirmPasswordForReset = (e) => {
        let passwordRule1 = /.{8,}/;
        let passwordRule2 = /[A-Z]/;
        let passwordRule3 = /[a-z]/;
        let passwordRule4 = /\d/;
        const password = e.target.value;
        if(passwordRule1.test(password)){
            setPasspasswordRule1(true)
        }else{
            setPasspasswordRule1(false)
        }
        if(passwordRule2.test(password)){
            setPasspasswordRule2(true)
        }else{
            setPasspasswordRule2(false)
        }
        if(passwordRule3.test(password)){
            setPasspasswordRule3(true)
        }else{
            setPasspasswordRule3(false)
        }
        if(passwordRule4.test(password)){
            setPasspasswordRule4(true)
        }else{
            setPasspasswordRule4(false)
        }
        setConfirmPasswordForReset(password);
    };

    return (
        <React.Fragment>
            <Col sm="5" md="8" lg="6" className="mx-auto p-0">
                    <Card className="h-100 shadow-lg" style={{overflow: 'hidden'}}>
                        <img src={CoverIMG} alt="Cover img" style={{width:"100%",height:"100%",objectFit:"cover"}}/>
                    </Card>
                </Col>
                <Col sm="5" md="8" lg="6" className="mx-auto p-0">
                <Card className="h-100 shadow-lg">
                        <div className="login-card">
                            <CardBody>
                                <div style={{margin: "0 2rem"}}>
                                    <div className={styles.LogoContent}>
                                        <div className={styles.LogoContent_Top} style={{marginBottom:'0'}}>
                                            <div style={{width: "100%",textAlign: "center"}}>
                                                <img src={require('../../assets/img/logo/skillsgaplogo.png').default}
                                                alt={"logo"} width="40%"
                                                height />
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <p style={{fontSize: "1.6rem",fontWeight:'600'}}>{title}</p>
                                    </div>
                                    <Form>
                                        <FormGroup>
                                            <Popover
                                            content={
                                                <div>
                                                    <p>{PasspasswordRule1? <Check size={18} color="#22db62"/>:<X size={18} color="#eb1414"/>}At least 8 characters long.</p>
                                                    <p>{PasspasswordRule2? <Check size={18} color="#22db62"/>:<X size={18} color="#eb1414"/>}Have at least one uppercase letter.</p>
                                                    <p>{PasspasswordRule3? <Check size={18} color="#22db62"/>:<X size={18} color="#eb1414"/>}Have at least one lowercase letter.</p>
                                                    <p>{PasspasswordRule4? <Check size={18} color="#22db62"/>:<X size={18} color="#eb1414"/>}Have at least one number.</p>
                                                </div>}
                                            placement="right"
                                            title={
                                                <span>
                                                    Password requirements:
                                                </span>}
                                            trigger="focus">
                                                <Input
                                                    bsSize="lg"
                                                    type="password"
                                                    name="password"
                                                    placeholder="Enter new password"
                                                    className={styles.commoninput}
                                                    value={newPasswordForReset}
                                                    onChange={onChangeNewPasswordForReset}
                                                    onFocus={onChangeNewPasswordForReset}
                                                />
                                            </Popover>
                                        </FormGroup>
                                        <FormGroup>
                                            <Popover
                                            content={
                                                <div>
                                                    <p>{PasspasswordRule1? <Check size={18} color="#22db62"/>:<X size={18} color="#eb1414"/>}At least 8 characters long.</p>
                                                    <p>{PasspasswordRule2? <Check size={18} color="#22db62"/>:<X size={18} color="#eb1414"/>}Have at least one uppercase letter.</p>
                                                    <p>{PasspasswordRule3? <Check size={18} color="#22db62"/>:<X size={18} color="#eb1414"/>}Have at least one lowercase letter.</p>
                                                    <p>{PasspasswordRule4? <Check size={18} color="#22db62"/>:<X size={18} color="#eb1414"/>}Have at least one number.</p>
                                                </div>}
                                            placement="right"
                                            title={
                                                <span>
                                                    Password requirements:
                                                </span>}
                                            trigger="focus">
                                                <Input
                                                    bsSize="lg"
                                                    type="password"
                                                    name="password"
                                                    placeholder="Confirm password"
                                                    className={styles.commoninput}
                                                    value={confirmPasswordForReset}
                                                    onChange={onChangeConfirmPasswordForReset}
                                                    onFocus={onChangeConfirmPasswordForReset}
                                                />
                                            </Popover>
                                        </FormGroup>
                                        <div className="text-right mt-3">

                                            <Button color="primary" size="lg" className="resetbutton" onClick={ChangePassIfOldPasswordValid}>
                                                Change Password
                                            </Button>

                                        </div>
                                        <div className={"mt-3"} style={{minHeight:"9rem"}}>
                                            {messagePassReset && (
                                                <Alert color={colors} isOpen={visible} toggle={onDismiss}>
                                                    <div className="alert-message" style={{padding:'0.95rem 1.4rem'}}>
                                                        <strong>{messagePassReset}</strong>
                                                    </div>
                                                </Alert>
                                            )}
                                        </div>
                                    </Form>
                                </div>
                            </CardBody>
                        </div>
                    </Card>
                </Col>
           
        </React.Fragment>
    )
}

export default ChangePassword;
