import React from "react";

import {  Container, Row } from "reactstrap";

import Main from "../components/Main";

const Auth = ({ children }) => (
    <React.Fragment>
        <div className="auth-box">
            <Main className="d-flex w-100 pt-1 pb-1 justify-content-center" >
                <Container style={{maxWidth:'1300px',margin:'0 auto'}}>
                     <Row>
                        {children}
                    </Row>
                </Container>
            </Main>
        </div>

    </React.Fragment>
);

export default Auth;
