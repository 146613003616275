import axios from "axios";

const API_URL = process.env.REACT_APP_COMPANY_API_URL;
const API_UPLOAD = process.env.REACT_APP_USER_API_URL
/*2022-9-30-2022-10-7*/
const GetCompanyPageInformation = (companyName) =>{
    return axios
    .post(API_URL + "GetCompanyPageInformation", {
        companyName
    })
    .then((response) => {
        return response.data;
    });
}
const getCompanyPageInfo = () => {
    return JSON.parse(sessionStorage.getItem("CompanyPageInfo"));
};
/*end*/
const GetCompanyList = () => {
    return axios
        .get(API_URL + "GetCompanyList")
        .then((response) => {
            //console.log(response.data.Message);
            return response.data;
        });
};
const GetCompanyDetailsById = (id) => {
     
    return axios
        .get(API_URL + "GetCompanyDetailsByCompanyId", { params: { companyId: id } })
        .then((response) => {
            //console.log(response.data.Message);
            return response.data;
        });
};
const DeleteCompanyById = (id) => {
    axios.get(API_URL + "DeleteCompanyById", { params: { companyId: id } }).then(response => {
        console.log(response);
    });
};

const AddCompanyDetails = (companyNameSignup, /*companyPasswordSignup,*/ companyABVSignup, companyStateSignup, companyZipSignup,
     /*companyEmailSignup, */companyCountrySignup, companyAddressSignup, companyIndustryNameSignup, /*role, firstName, lastName,*/ companyId, partnerId, companyDomainSignup,colour1,colour2,
    need_client_additional_logo,banner_text,section_1_title,body_end_text,section_1_body,need_banner_image,client_additional_logo_type,banner_image_type,need_company_logo,company_logo_type
    ) => {
     
    var companyDetails = new FormData();
    companyDetails.append("company_name", companyNameSignup);
    companyDetails.append("companyId", companyId);
    companyDetails.append("company_abn", companyABVSignup)
    //companyDetails.append("email", companyEmailSignup)
    //companyDetails.append("password", companyPasswordSignup)
    // companyDetails.append("companyLogo", companyLogoSignup)

    companyDetails.append("companyCountry", companyCountrySignup)
    companyDetails.append("companyAddress", companyAddressSignup)
    companyDetails.append("companyIndustry", companyIndustryNameSignup)
    companyDetails.append("companyZip", companyZipSignup)
    companyDetails.append("companyState", companyStateSignup)
    companyDetails.append("companyDomain", companyDomainSignup)
    companyDetails.append("colour1", colour1)
    companyDetails.append("colour2", colour2)
    companyDetails.append("need_client_additional_logo", need_client_additional_logo)
    companyDetails.append("banner_text", banner_text)
    companyDetails.append("section_1_title", section_1_title)
    companyDetails.append("body_end_text", body_end_text)
    companyDetails.append("section_1_body", section_1_body)
    companyDetails.append("need_banner_image", need_banner_image)
    companyDetails.append("client_additional_logo_type", client_additional_logo_type)
    companyDetails.append("banner_image_type", banner_image_type)
    companyDetails.append("need_company_logo", need_company_logo)
    companyDetails.append("company_logo_type", company_logo_type)
    //companyDetails.append("roleId", role)
    //companyDetails.append("userFirstName", firstName)
    //companyDetails.append("userLastName", lastName)
    companyDetails.append("partnerId", partnerId)

    return axios.post(API_URL + "AddCompanyDetails", companyDetails);
};
const UploadFilePost = (type,companyName,filelist,partnerName,userId) => {
    var UploadData = new FormData();
    if(type === 4){
        UploadData.append("type",type);
        UploadData.append("userId",companyName);
        UploadData.append("companyName",'');
        UploadData.append("filelist",filelist);
        return axios.post(API_UPLOAD + "UploadFilePost",UploadData)
    }else if(type === 5 || type === 6){
        UploadData.append("type",type);
        UploadData.append("companyName",companyName);
        UploadData.append("filelist",filelist);
        UploadData.append("partnerName",partnerName);
        return axios.post(API_UPLOAD + "UploadFilePost",UploadData)
    }else if(type === 7){
        UploadData.append("type",type);
        UploadData.append("companyName",companyName);
        UploadData.append("filelist",filelist);
        UploadData.append("userId",userId);
        return axios.post(API_UPLOAD + "UploadFilePost",UploadData)
    } else{ 
        UploadData.append("type",type);
        UploadData.append("companyName",companyName);
        UploadData.append("filelist",filelist);
        return axios.post(API_UPLOAD + "UploadFilePost",UploadData)
    }
};

const GetCountryList = () => {
    return axios
        .get(API_URL + "GetCountryList")
        .then((response) => {
            //console.log(response.data.Message);
            return response.data;
        });
};

const GetCompanyPageUrl = (companyemail) => {
    return axios
        .post(API_URL + "GetCompanyPageUrl",{email: companyemail })
        .then((response) => {
            return response.data;
        });
};

const AddCompanyCreditDetails = (companyAddCreditsSignUp, companyId, currentUserId) => {
     
    var companyCreditDetails = new FormData();
    companyCreditDetails.append("companyId", companyId);
    companyCreditDetails.append("currentUserId", currentUserId);
    companyCreditDetails.append("companyCredits", companyAddCreditsSignUp)

    return axios.post(API_URL + "AddCompanyCreditDetails", companyCreditDetails);
};
const GetCompanyDetailsByFromUserEmail = (email) => {

    return axios
        .get(API_URL + "GetCompanyDetailsByFromUserEmail", { params: { email: email } })
        .then((response) => {
            //console.log(response.data.Message);
            return response.data;
        });
};
const GetCompanyListByPartnerId = (partnerId) => {

    return axios
        .post(API_URL + "GetCompanyListByPartnerId", { "partnerId": partnerId })
        .then((response) => {
            //console.log(response.data.Message);
            return response.data;
        });
};

const GetCompanyCreditUsed = (userId,startDate,endDate) => {

    return axios
        .post(API_URL + "GetCompanyCreditUsed", { "userId": userId ,"startDate": startDate,"endDate": endDate})
        .then((response) => {
            return response.data;
        });
};

const UpdateCompanyComingSoon = (companyId,comingSoon) => {

    return axios
        .post(API_URL + "UpdateCompanyComingSoon", { "companyId": companyId ,"comingSoon": comingSoon})
        .then((response) => {
            return response.data;
        });
};

export default {
    getCompanyPageInfo,
    GetCompanyList, 
    DeleteCompanyById, 
    AddCompanyDetails, 
    GetCompanyDetailsById, 
    GetCountryList, 
    AddCompanyCreditDetails, 
    GetCompanyDetailsByFromUserEmail,
    GetCompanyPageInformation,
    GetCompanyPageUrl,
    UploadFilePost,
    GetCompanyListByPartnerId,
    GetCompanyCreditUsed,
    UpdateCompanyComingSoon
};