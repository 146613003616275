import React, { useState, useEffect } from "react";

// Services
import AuthService from "../../../services/auth.service.js";
import PartnerService from "../../../services/partners.service.js";
import CompanyService from "../../../services/companies.service";

// Components
import {
    Card,
    CardHeader,
    CardTitle,
    Container,
    Col,
    Row,
    Table,
    CardBody,
    FormGroup,
    Input,
} from "reactstrap";
import { Filter, Settings, ShoppingBag, Plus, X, Search } from "react-feather";
import { Button, Modal, Alert } from "react-bootstrap";
import Pagination from "../../../../src/components/Pagination";

// Assets
import iconEdit from "../../../assets/img/icons/edit.svg";
import iconDeleteUser from "../../../assets/img/icons/delete.svg";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure()

/*
* Partner List View
*
* The following view displays the list
* of Partners registered under the platform
*
* The Partners displayed will depend on the
* user role. (Eg: Super Admin)
*
* */
const SgaPartnersList = (props) => {
    var currentUser = AuthService.getCurrentUser();
    var currentCompany = AuthService.getCurrentCompany();
    // if (currentUser == null || currentUser == undefined) {
    //     props.history.push("/");
    //     window.location.reload();
    // }
    // if (
    //     currentUser.roleName === "user" ||
    //     currentUser.roleName === "team-member" ||
    //     currentUser.roleName === "team-leader" ||
    //     currentUser.roleName === "company" ||
    //     currentUser.roleName === "partner"
    // ) {
    //     props.history.push("/dashboard/default");
    //     window.location.reload();
    // }
    const [partners, setPartners] = useState([]);
    const [countries, setCountry] = useState([]);

    const [partnerMessage, setMessage] = useState(null);
    const [partnerAddMessage, setPartnerAddMessage] = useState(null);

    //const [partnerFirstNameSignup, setPartnerFirstNameSignup] = useState("");
    const [partnerId, setPartnerId] = useState("");

    const [partnerAddCreditsSignUp, setPartnerAddCreditsSignUp] = useState("");

    //const [partnerLastNameSignup, setPartnerLastNameSignup] = useState("");
    const [partnerNameSignup, setPartnerNameSignup] = useState("");
    // const [partnerEmailSignup, setPartnerEmailSignup] = useState("");
    // const [partnerPasswordSignup, setPartnerPasswordSignup] = useState("");
    const [partnerAddressSignup, setPartnerAddressSignup] = useState("");
    const [partnerABNSignup, setPartnerABNSignup] = useState("");
    const [partnerPhoneNumber, setPartnerPhoneNumber] = useState("");
    const [partnerEmail, setPartnerEmail] = useState("");
    const [partnerWebsit, setPartnerWebsit] = useState("");
    const [partnerStateSignup, setPartnerStateSignup] = useState("");
    const [partnerCountrySignup, setPartnerCountrySignup] = useState("");
    const [partnerZipSignup, setPartnerZipSignup] = useState("");

    const [partnerLogoSignup, setPartnerLogoSignup] = useState("");
    const [partnerLogotxt, setPartnerLogotxt] = useState("Choose file");
    const [partnerLogoType, setPartnerLogoType] = useState("");
    const [need_partner_logo, setNeed_partner_logo] = useState(false);

    const [partnerLogoInverseSignup, setPartnerLogoInverseSignup] = useState("");
    const [partnerLogoInversetxt, setPartnerLogoInversetxt] = useState("Choose file");
    const [partnerLogoInverseType, setPartnerLogoInverseType] = useState("");
    const [need_partner_logo_inverse, setNeed_partner_logo_inverse] = useState(false);

    const [PartnerModalTitle, setPartnerModalTitle] = useState("");
    const [PartnerModalButtonTitle, setPartnerModalButtonTitle] = useState("");

    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(50);
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    //componentDidMount
    useEffect(() => {
        //Fill partner list
        const partnerData = () => {
            var partnerList = PartnerService.GetPartnerList();

            var details = partnerList
                .then((response) => {
                    return response;
                })
                .then((data) => {
                    setPartners(data.partnerDetails);
                })
                .catch((e) => {
                    console.log(e);
                });
        };

        //fill countries dropdown data
        const countryData = () => {
            var countrylist = PartnerService.GetCountryList();

            var details = countrylist
                .then((response) => {
                    return response;
                })
                .then((data) => {
                    setCountry(data);
                    setPartnerCountrySignup(data[0].id)
                })
                .catch((e) => {
                    console.log(e);
                });
        };
        partnerData();
        countryData();
    }, []);

    //add partner model
    const [addPartnerModalshow, setAddPartnerModalshow] = useState(false);
    const addPartnerModalClose = () => setAddPartnerModalshow(false);
    const addPartnerModal = () => setAddPartnerModalshow(true);

    //add credits partner model
    const [addCreditPartnerModalshow, setAddCreditPartnerModalshow] =
        useState(false);
    const addCreditPartnerModalClose = () => setAddCreditPartnerModalshow(false);
    const addCreditDetailPartnerModal = () => setAddCreditPartnerModalshow(true);

    const addCreditPartnerModal = (id) => {
        setPartnerModalTitle("Add Credits For Partner");
        setPartnerModalButtonTitle("Add Credits");
        setAddCreditPartnerModalshow(true);
        setPartnerId(id);

        setPartnerAddCreditsSignUp("");
        setMessage("");
        setPartnerAddMessage("");

        console.log(currentUser.id);
    };

    //close the add credits Partner modal
    const addCreditPartnersDetailsModalClose = () => {
        setAddCreditPartnerModalshow(false);
        setPartnerAddMessage("");
    };

    const handleLinkClk = (id, name) => {
        console.log("partner id===" + id);
        props.history.push({
            pathname: "/PartnerView",
            state: { data: id, pName: name },
        });
    };

    const editPartnerModal = (id) => {
        setPartnerModalTitle("Edit Partner");
        setPartnerModalButtonTitle("Save Changes");
        var partnerDetails = PartnerService.GetPartnerDetailsById(id);
        partnerDetails
            .then((response) => {
                return response;
            })
            .then((data) => {
                //setPartnerFirstNameSignup(data.PartnerFirstName);
                // setPartnerLastNameSignup(data.PartnerLastName);
                setPartnerNameSignup(data.PartnerName);
                // setPartnerEmailSignup(data.PartnerEmail);
                //  setPartnerPasswordSignup(data.PartnerPassword);
                setPartnerAddressSignup(data.PartnerAddress);
                setPartnerABNSignup(data.PartnerAbn);
                setPartnerStateSignup(data.PartnerState);
                setPartnerCountrySignup(data.PartnerCountry);
                setPartnerZipSignup(data.PartnerZipCode);
                setPartnerPhoneNumber(data.PhoneNumber);
                setPartnerEmail(data.Email);
                setPartnerWebsit(data.Website);

                setPartnerId(data.PartnerId)

                setAddPartnerModalshow(true);
                setPartnerId(id);
                setPartnerLogoInversetxt('Choose file');
                setPartnerLogotxt('Choose file');
                setPartnerLogoInverseSignup('');
                setPartnerLogoSignup('');
                setPartnerLogoInverseType('');
                setPartnerLogoType('');
                setNeed_partner_logo(false);
                setNeed_partner_logo_inverse(false);
            })
            .catch(e => {
                toast.error("There is some error fetching the details of the partner", { position: toast.POSITION.TOP_CENTER });
                console.log(e);
            })


    };

    //close the add Partner modal
    const addPartnersDetailsModalClose = () => {
        setAddPartnerModalshow(false);
        setPartnerAddMessage("");
    };

    //displays the add partner modal
    const addPartnersDetailsModalShow = () => {
        setPartnerLogoInversetxt('Choose file');
        setPartnerLogotxt('Choose file');
        setPartnerLogoInverseSignup('');
        setPartnerLogoSignup('');
        setPartnerLogoInverseType('');
        setPartnerLogoType('');
        setNeed_partner_logo(false);
        setNeed_partner_logo_inverse(false);
        setAddPartnerModalshow(true);
        setPartnerAddMessage("");
        setPartnerModalTitle("Add Partner");
        setPartnerModalButtonTitle("Add Partner");

        setPartnerNameSignup("");
        setPartnerAddressSignup("");
        setPartnerABNSignup("");
        setPartnerPhoneNumber("");
        setPartnerEmail("");
        setPartnerWebsit("");
        setPartnerStateSignup("");
        setPartnerCountrySignup(countries[0].id);
        setPartnerZipSignup("");

        setPartnerId("0");
        setMessage("");
    };

    // add or updates the partner, if the partner id is null/0 then inserts the partner else updates
    const addPartnerDetails = () => {
        if (
            (partnerNameSignup !== "" &&
                partnerAddressSignup !== "" &&
                partnerABNSignup !== "" &&
                partnerStateSignup !== "" &&
                partnerCountrySignup !== "" &&
                partnerZipSignup !== "" &&
                partnerId &&
                partnerLogoInverseSignup !== "" &&
                partnerLogoSignup !== "" &&
                PartnerModalButtonTitle == "Add Partner") ||
            (partnerNameSignup !== "" &&
                partnerAddressSignup !== "" &&
                partnerABNSignup !== "" &&
                partnerStateSignup !== "" &&
                partnerCountrySignup !== "" &&
                partnerZipSignup !== "" &&
                partnerId &&
                PartnerModalButtonTitle == "Save Changes")
        ) {
            if (PartnerModalButtonTitle == "Add Partner") {
                CompanyService.UploadFilePost(5, '', partnerLogoSignup, partnerNameSignup).then((response) => {
                    if (response.data.Status !== "Success") {
                        setAddPartnerModalshow(true);
                        toast.error(response.data, { position: toast.POSITION.TOP_CENTER })
                    } else if (response.data.Status === "Success") {
                        CompanyService.UploadFilePost(6, '', partnerLogoInverseSignup, partnerNameSignup).then((response) => {
                            if (response.data.Status !== "Success") {
                                setAddPartnerModalshow(true);
                                toast.error(response.data, { position: toast.POSITION.TOP_CENTER })
                            } else if (response.data.Status === "Success") {
                                PartnerService.AddPartnerDetails(
                                    partnerNameSignup,
                                    partnerLogoSignup,
                                    partnerAddressSignup,
                                    partnerABNSignup,
                                    partnerStateSignup,
                                    partnerCountrySignup,
                                    partnerZipSignup,
                                    partnerId,
                                    need_partner_logo,
                                    partnerLogoType,
                                    need_partner_logo_inverse,
                                    partnerLogoInverseType,
                                    partnerPhoneNumber,
                                    partnerEmail,
                                    partnerWebsit
                                ).then((response) => {
                                    if (response.data.Status === "Success") {
                                        setAddPartnerModalshow(false);
                                        UpdataPartnerData();
                                        if (partnerId === "0") {
                                            toast.success("Partner Added Successfully", { position: toast.POSITION.TOP_CENTER });
                                        } else {
                                            toast.success("Partner Updated Successfully", { position: toast.POSITION.TOP_CENTER });
                                        }
                                    } else {
                                        setAddPartnerModalshow(true);
                                        toast.success(response.data.Message, { position: toast.POSITION.TOP_CENTER });
                                    }
                                }
                                );
                            }
                        })
                    }
                })
            } else if (PartnerModalButtonTitle == "Save Changes") {
                if (partnerLogoSignup !== '' && partnerLogoInverseSignup !== '') {
                    CompanyService.UploadFilePost(5, '', partnerLogoSignup, partnerNameSignup).then((response) => {
                        if (response.data.Status !== "Success") {
                            setAddPartnerModalshow(true);
                            toast.error(response.data, { position: toast.POSITION.TOP_CENTER })
                        } else if (response.data.Status === "Success") {
                            CompanyService.UploadFilePost(6, '', partnerLogoInverseSignup, partnerNameSignup).then((response) => {
                                if (response.data.Status !== "Success") {
                                    setAddPartnerModalshow(true);
                                    toast.error(response.data, { position: toast.POSITION.TOP_CENTER })
                                } else if (response.data.Status === "Success") {
                                    PartnerService.AddPartnerDetails(
                                        partnerNameSignup,
                                        partnerLogoSignup,
                                        partnerAddressSignup,
                                        partnerABNSignup,
                                        partnerStateSignup,
                                        partnerCountrySignup,
                                        partnerZipSignup,
                                        partnerId,
                                        need_partner_logo,
                                        partnerLogoType,
                                        need_partner_logo_inverse,
                                        partnerLogoInverseType,
                                        partnerPhoneNumber,
                                        partnerEmail,
                                        partnerWebsit
                                    ).then((response) => {
                                        if (response.data.Status === "Success") {
                                            setAddPartnerModalshow(false);
                                            UpdataPartnerData();
                                            if (partnerId === "0") {
                                                toast.success("Partner Added Successfully", { position: toast.POSITION.TOP_CENTER });
                                            } else {
                                                toast.success("Partner Updated Successfully", { position: toast.POSITION.TOP_CENTER });
                                            }
                                        } else {
                                            setAddPartnerModalshow(true);
                                            toast.success(response.data.Message, { position: toast.POSITION.TOP_CENTER });
                                        }
                                    }
                                    );
                                }
                            })
                        }
                    })
                } else if (partnerLogoSignup !== '' && partnerLogoInverseSignup == '') {
                    CompanyService.UploadFilePost(5, '', partnerLogoSignup, partnerNameSignup).then((response) => {
                        if (response.data.Status !== "Success") {
                            setAddPartnerModalshow(true);
                            toast.error(response.data, { position: toast.POSITION.TOP_CENTER })
                        } else if (response.data.Status === "Success") {
                            PartnerService.AddPartnerDetails(
                                partnerNameSignup,
                                partnerLogoSignup,
                                partnerAddressSignup,
                                partnerABNSignup,
                                partnerStateSignup,
                                partnerCountrySignup,
                                partnerZipSignup,
                                partnerId,
                                need_partner_logo,
                                partnerLogoType,
                                need_partner_logo_inverse,
                                partnerLogoInverseType,
                                partnerPhoneNumber,
                                partnerEmail,
                                partnerWebsit
                            ).then((response) => {
                                if (response.data.Status === "Success") {
                                    setAddPartnerModalshow(false);
                                    UpdataPartnerData();
                                    if (partnerId === "0") {
                                        toast.success("Partner Added Successfully", { position: toast.POSITION.TOP_CENTER });
                                    } else {
                                        toast.success("Partner Updated Successfully", { position: toast.POSITION.TOP_CENTER });
                                    }
                                } else {
                                    setAddPartnerModalshow(true);
                                    toast.success(response.data.Message, { position: toast.POSITION.TOP_CENTER });
                                }
                            }
                            );
                        }
                    })
                } else if (partnerLogoSignup == '' && partnerLogoInverseSignup !== '') {
                    CompanyService.UploadFilePost(6, '', partnerLogoInverseSignup, partnerNameSignup).then((response) => {
                        if (response.data.Status !== "Success") {
                            setAddPartnerModalshow(true);
                            toast.error(response.data, { position: toast.POSITION.TOP_CENTER })
                        } else if (response.data.Status === "Success") {
                            PartnerService.AddPartnerDetails(
                                partnerNameSignup,
                                partnerLogoSignup,
                                partnerAddressSignup,
                                partnerABNSignup,
                                partnerStateSignup,
                                partnerCountrySignup,
                                partnerZipSignup,
                                partnerId,
                                need_partner_logo,
                                partnerLogoType,
                                need_partner_logo_inverse,
                                partnerLogoInverseType,
                                partnerPhoneNumber,
                                partnerEmail,
                                partnerWebsit
                            ).then((response) => {
                                if (response.data.Status === "Success") {
                                    setAddPartnerModalshow(false);
                                    UpdataPartnerData();
                                    if (partnerId === "0") {
                                        toast.success("Partner Added Successfully", { position: toast.POSITION.TOP_CENTER });
                                    } else {
                                        toast.success("Partner Updated Successfully", { position: toast.POSITION.TOP_CENTER });
                                    }
                                } else {
                                    setAddPartnerModalshow(true);
                                    toast.success(response.data.Message, { position: toast.POSITION.TOP_CENTER });
                                }
                            }
                            );
                        }
                    })
                }else if (partnerLogoSignup == '' && partnerLogoInverseSignup == ''){
                    PartnerService.AddPartnerDetails(
                        partnerNameSignup,
                        partnerLogoSignup,
                        partnerAddressSignup,
                        partnerABNSignup,
                        partnerStateSignup,
                        partnerCountrySignup,
                        partnerZipSignup,
                        partnerId,
                        need_partner_logo,
                        partnerLogoType,
                        need_partner_logo_inverse,
                        partnerLogoInverseType,
                        partnerPhoneNumber,
                        partnerEmail,
                        partnerWebsit
                    ).then((response) => {
                        if (response.data.Status === "Success") {
                            setAddPartnerModalshow(false);
                            UpdataPartnerData();
                            if (partnerId === "0") {
                                toast.success("Partner Added Successfully", { position: toast.POSITION.TOP_CENTER });
                            } else {
                                toast.success("Partner Updated Successfully", { position: toast.POSITION.TOP_CENTER });
                            }
                        } else {
                            setAddPartnerModalshow(true);
                            toast.success(response.data.Message, { position: toast.POSITION.TOP_CENTER });
                        }
                    }
                    );
                }
            }
        } else {
            setAddPartnerModalshow(true);
            toast.error("Kindly fill all the fields", { position: toast.POSITION.TOP_CENTER });
        }
    };

    const UpdataPartnerData = () => {
        var partnerList = PartnerService.GetPartnerList();

        var details = partnerList
            .then((response) => {
                return response;
            })
            .then((data) => {
                setPartners(data.partnerDetails);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const onChangePartnerNameSignup = (e) => {
        const partnerName = e.target.value;
        setPartnerNameSignup(partnerName);
    };

    const onChangePartnerAddressSignup = (e) => {
        const partnerAddress = e.target.value;
        setPartnerAddressSignup(partnerAddress);
    };

    const onChangePartnerABN = (e) => {
        const partnerABN = e.target.value;
        setPartnerABNSignup(partnerABN);
    };

    const onChangePartnerStateSignup = (e) => {
        const partnerState = e.target.value;
        setPartnerStateSignup(partnerState);
    };

    const onChangePartnerCountrySignup = (e) => {
        const partnerCountry = e.target.value;
        setPartnerCountrySignup(partnerCountry);
    };

    const onChangePartnerZipSignup = (e) => {
        const partnerZip = e.target.value;
        setPartnerZipSignup(partnerZip);
    };
    const onChangePartnerPhoneNumber = (e) => {
        const PartnerPhoneNumber = e.target.value;
        setPartnerPhoneNumber(PartnerPhoneNumber);
    };
    const onChangePartnerEmail = (e) => {
        const PartnerEmail = e.target.value;
        setPartnerEmail(PartnerEmail);
    };
    const onChangePartnerWebsit = (e) => {
        const PartnerWebsit = e.target.value;
        setPartnerWebsit(PartnerWebsit);
    };
    const onChangeLogoSignup = (e) => {
        const partnerlogo = e.target.files[0];
        if (e.target.files[0]) {
            setPartnerLogotxt(e.target.files[0].name)
            setPartnerLogoType(partnerlogo.name.split('.')[partnerlogo.name.split('.').length - 1])
            setNeed_partner_logo(true);
            setPartnerLogoSignup(partnerlogo);
        } else {
            setPartnerLogotxt('Choose file');
            setPartnerLogoType('')
            setNeed_partner_logo(false);
            setPartnerLogoSignup('');
        }
    };

    const onChangeLogoInverseSignup = (e) => {
        const partnerInverselogo = e.target.files[0];
        if (e.target.files[0]) {
            setPartnerLogoInversetxt(e.target.files[0].name)
            setPartnerLogoInverseType(partnerInverselogo.name.split('.')[partnerInverselogo.name.split('.').length - 1])
            setNeed_partner_logo_inverse(true);
            setPartnerLogoInverseSignup(partnerInverselogo);
        } else {
            setPartnerLogoInversetxt('Choose file');
            setPartnerLogoInverseType('')
            setNeed_partner_logo_inverse(false);
            setPartnerLogoInverseSignup('');
        }
    };

    //for add credits partner
    const onChangePartnerAddCreditsSignup = (e) => {
        const partnerCredits = e.target.value;
        setPartnerAddCreditsSignUp(partnerCredits);
    };

    //code for add  credits for partners

    const addCreditDetailsOfPartner = () => {
        //code for credit details

        var currentUserId = currentUser.id;
        if (partnerAddCreditsSignUp !== "" && partnerId) {

            PartnerService.AddPartnerCreditDetails(partnerAddCreditsSignUp, partnerId, currentUserId).then(
                (response) => {
                    if (response.data.Status === "Success") {
                        setAddCreditPartnerModalshow(false);
                        toast.success("Credit Successfully Added Into Partner Account !!", { position: toast.POSITION.TOP_CENTER });
                    }
                }
            );
        }

    };

    if (currentUser || currentCompany) {
        return (
            <Card>
                <CardHeader>
                    <CardTitle className="mb-0 d-flex align-items-center">
                        <h2 className="d-inline-block mr-3 mb-0">Partners</h2>
                        <button
                            className="p-2 text-white bg-primary rounded-circle"
                            onClick={addPartnersDetailsModalShow}
                        >
                            <Plus size={28} />
                        </button>
                    </CardTitle>
                </CardHeader>
                <CardBody>
                    {/*model for add partner */}
                    <Modal
                        show={addPartnerModalshow}
                        onHide={addPartnersDetailsModalClose}
                        backdrop="static"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>{PartnerModalTitle}</Modal.Title>
                            {"\n"}
                        </Modal.Header>
                        <Modal.Body>
                            <Container>
                                <Row>
                                    <Col xs={6} md={6}>
                                        <FormGroup>
                                            <label style={{ color: '#000000', fontWeight: '600' }}>
                                                Partner Name
                                            </label>

                                            <Input
                                                bsSize="lg"
                                                type="text"
                                                name="partner-name"
                                                placeholder="Partner name" onChange={onChangePartnerNameSignup}
                                                value={partnerNameSignup}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col xs={6} md={6}>
                                        <FormGroup>
                                            <label style={{ color: '#000000', fontWeight: '600' }}>
                                                Partner Logo
                                            </label>
                                            <div className="input-group">
                                                <div className="custom-file">
                                                    <input type="file" className="custom-file-input" id="inputGroupFile01" aria-describedby="inputGroupFileAddon01" onChange={onChangeLogoSignup}></input>
                                                    <label className="custom-file-label" style={{ overflow: 'hidden', lineHeight: '1.8rem', whiteSpace: 'nowrap', textOverflow: 'ellipsis', paddingRight: '5rem' }} htmlFor="inputGroupFile01">{partnerLogotxt}</label>
                                                </div>
                                            </div>
                                        </FormGroup>
                                    </Col>

                                    <Col xs={6} md={6}>
                                        <FormGroup>
                                            <label style={{ color: '#000000', fontWeight: '600' }}>
                                                Address
                                            </label>
                                            <Input
                                                bsSize="lg"
                                                type="text"
                                                name="address"
                                                placeholder="Address"
                                                onChange={onChangePartnerAddressSignup}
                                                value={partnerAddressSignup}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col xs={6} md={6}>
                                        <FormGroup>
                                            <label style={{ color: '#000000', fontWeight: '600' }}>
                                                Partner Logo Inverse
                                            </label>
                                            <div className="input-group">
                                                <div className="custom-file">
                                                    <input type="file" className="custom-file-input" id="inputGroupFile02" aria-describedby="inputGroupFileAddon02" onChange={onChangeLogoInverseSignup} ></input>
                                                    <label className="custom-file-label" style={{ overflow: 'hidden', lineHeight: '1.8rem', whiteSpace: 'nowrap', textOverflow: 'ellipsis', paddingRight: '5rem' }} htmlFor="inputGroupFile02">{partnerLogoInversetxt}</label>
                                                </div>
                                            </div>
                                        </FormGroup>
                                    </Col>

                                    {/* <Col xs={6} md={6}>
                                        <FormGroup>

                                            <Input
                                                bsSize="lg"
                                                type="file"
                                                name="logo"
                                                onChange={onChangeLogoSignup}
                                            />
                                        </FormGroup>
                                    </Col> */}

                                    <Col xs={6} md={6}>
                                        <FormGroup>
                                            <label style={{ color: '#000000', fontWeight: '600' }}>
                                                State
                                            </label>
                                            <Input
                                                bsSize="lg"
                                                type="text"
                                                name="state"
                                                placeholder="State"
                                                onChange={onChangePartnerStateSignup}
                                                value={partnerStateSignup}
                                            />
                                        </FormGroup>
                                    </Col>
 
                                    <Col xs={6} md={6}>
                                        <FormGroup>
                                            <label style={{ color: '#000000', fontWeight: '600' }}>
                                                ABN
                                            </label>
                                            <Input
                                                bsSize="lg"
                                                type="text"
                                                name="abn"
                                                placeholder="ABN"
                                                onChange={onChangePartnerABN}
                                                value={partnerABNSignup}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col xs={6} md={6}>
                                        <FormGroup>
                                            <label style={{ color: '#000000', fontWeight: '600' }}>
                                                Zipcode
                                            </label>
                                            <Input
                                                bsSize="lg"
                                                type="text"
                                                name="zipcode"
                                                placeholder="Zipcode"
                                                onChange={onChangePartnerZipSignup}
                                                value={partnerZipSignup}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col xs={6} md={6}>
                                        <FormGroup>
                                            <label style={{ fontWeight: '600' }}>
                                                Choose Country :
                                            </label>
                                            <select
                                                className="custom-select"
                                                value={partnerCountrySignup}
                                                onChange={onChangePartnerCountrySignup}
                                            >
                                                {countries &&
                                                    countries.length > 0 &&
                                                    countries.map((schema) => {
                                                        return (
                                                            <option key={schema.id} value={schema.id}>
                                                                {schema.country_name}
                                                            </option>
                                                        );
                                                    })}
                                            </select>
                                        </FormGroup>
                                    </Col>

                                    <Col xs={6} md={6}>
                                        <FormGroup>
                                            <label style={{ color: '#000000', fontWeight: '600' }}>
                                            Phone Number
                                            </label>
                                            <Input
                                                bsSize="lg"
                                                type="text"
                                                name="phonenumber"
                                                placeholder="PhoneNumber"
                                                onChange={onChangePartnerPhoneNumber}
                                                value={partnerPhoneNumber}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col xs={6} md={6}>
                                        <FormGroup>
                                            <label style={{ color: '#000000', fontWeight: '600' }}>
                                            Email
                                            </label>
                                            <Input
                                                bsSize="lg"
                                                type="text"
                                                name="Email"
                                                placeholder="Email"
                                                onChange={onChangePartnerEmail}
                                                value={partnerEmail}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col xs={6} md={6}>
                                        <FormGroup>
                                            <label style={{ color: '#000000', fontWeight: '600' }}>
                                                Websit
                                            </label>
                                            <Input
                                                bsSize="lg"
                                                type="text"
                                                name="Websit"
                                                placeholder="Websit"
                                                onChange={onChangePartnerWebsit}
                                                value={partnerWebsit}
                                            />
                                        </FormGroup>
                                    </Col>

                                </Row>
                            </Container>
                        </Modal.Body>
                        <Modal.Footer>
                            {partnerAddMessage && (
                                <Alert variant="danger">{partnerAddMessage}</Alert>
                            )}

                            <Button variant="primary" onClick={addPartnerDetails}>
                                {PartnerModalButtonTitle}
                            </Button>
                            <Button
                                variant="secondary"
                                onClick={addPartnersDetailsModalClose}
                            >
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    {/*model for add partner */}


                    {/*model for add credits partner */}
                    <Modal show={addCreditPartnerModalshow} onHide={addCreditPartnersDetailsModalClose}
                        backdrop="static">
                        <Modal.Header closeButton>
                            <Modal.Title>{PartnerModalTitle}</Modal.Title>{"\n"}

                        </Modal.Header>
                        <Modal.Body>

                            <Container>
                                <Row>

                                    <Col xs={6} md={6}>
                                        <FormGroup>

                                            <Input
                                                bsSize="lg"
                                                type="text"
                                                name="partner-credits"
                                                placeholder="Enter partner credits"
                                                onChange={onChangePartnerAddCreditsSignup}
                                                value={partnerAddCreditsSignUp}
                                            />
                                        </FormGroup>
                                    </Col>


                                </Row>
                            </Container>

                        </Modal.Body>
                        <Modal.Footer>
                            {partnerAddMessage && <Alert variant="danger">{partnerAddMessage}</Alert>}

                            <Button variant="primary" onClick={addCreditDetailsOfPartner}>
                                {PartnerModalButtonTitle}
                            </Button>
                            <Button variant="secondary" onClick={addCreditPartnersDetailsModalClose}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    {/*model for add credit partner */}


                    {partnerMessage && <Alert variant="success" style={{ "width": "400px" }}>{partnerMessage}</Alert>}

                    <Table striped hover>
                        <thead>
                            <tr>
                                <th></th>
                                <th>Partner Id</th>
                                <th>Logo</th>
                                <th>PartnerName</th>
                                <th className="text-center">Edit</th>
                                {currentUser.RoleId === 0 ? (
                                    <th className="text-center">Add Credits</th>
                                ) : (
                                    <div></div>
                                )}
                                {currentUser.RoleId === 0 ? (
                                    <th className="text-center">Remove</th>
                                ) : (
                                    ""
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {partners.slice(indexOfFirstPost, indexOfLastPost).map((ptnr) => {
                                return (
                                    <tr key={ptnr.Id}>
                                        <td>
                                            <label className="checkbox-container">
                                                <input type="checkbox" checked="" />
                                                <span className="checkmark"></span>
                                            </label>
                                        </td>
                                        <td>{ptnr.Id}</td>
                                        <td>
                                            {ptnr.PartnerLogo !== "" && <img
                                                src={ptnr.PartnerLogo}
                                                width="48"
                                                height="48"
                                                className="rounded-circle mr-2"
                                                alt="Avatar"
                                            />}
                                        </td>
                                        <td>
                                            <div
                                                className="my-pointer"
                                                onClick={() => handleLinkClk(ptnr.Id, ptnr.PartnerName)}
                                            >
                                                {" "}
                                                {ptnr.PartnerName}{" "}
                                            </div>
                                        </td>
                                        <td>
                                            <button
                                                className="btn d-block mx-auto"
                                                onClick={() => editPartnerModal(ptnr.Id)}
                                            >
                                                <img src={iconEdit} alt="" />
                                            </button>
                                        </td>

                                        {currentUser.RoleId === 0 ? (
                                            <td>
                                                <button
                                                    className="btn text-primary d-block mx-auto"
                                                    onClick={() => addCreditPartnerModal(ptnr.Id)}
                                                >
                                                    <ShoppingBag size={50} />
                                                </button>
                                            </td>
                                        ) : (
                                            <div></div>
                                        )}

                                        {currentUser.RoleId === 0 ? (
                                            <td>
                                                <button className="btn d-block mx-auto">
                                                    <img src={iconDeleteUser} alt="" />
                                                </button>
                                            </td>
                                        ) : (
                                            ""
                                        )}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                    <Pagination
                        postsPerPage={postsPerPage}
                        totalPosts={partners.length}
                        paginate={paginate}
                    />
                    {/*Table for partner list*/}
                </CardBody>
            </Card>
        );
    } else {
        return <Card></Card>;
    }
};

const Tables = (props) => (
    <div id="partner_div">
        <Container fluid className="p-0">
            <Row className="mb-2 mb-xl-4">
                <Col xs="auto" className="ml-auto text-right mt-n1">
                    <Button color="primary" className="shadow-sm mr-1">
                        <Filter className="feather" />
                    </Button>
                    {/* 暂时隐藏（设置按钮）
                    <Button color="primary" className="shadow-sm">
                        <Settings className="feather"/>
                    </Button> */}
                </Col>
            </Row>

            <SgaPartnersList {...props} />
        </Container>
    </div>
);

export default Tables;
