import axios from "axios";

const API_URL = process.env.REACT_APP_USER_API_URL;
const GetUserList = () => {
    return axios
        .get(API_URL + "GetAllUsers")
        .then((response) => {
            return response.data;
        });
};
const UpdateUserRole = (userId, roleId) => {
    return axios.get(API_URL + "UpdateUserRole?" + "userId=" + userId + "&roleId=" + roleId).then((response) => {
        return response.data;
    });
};
const DeleteUserById = (id) => {
    return axios.get(API_URL + "DeleteUserById", { params: { userId: id } }).then(response => {
       return response.data;
    });
};
const GetJobRoles = () => {
    return axios
        .get(API_URL + "GetJobRoles")
        .then((response) => {
            //console.log(response.data.Message);
            return response.data;
        });
};

const GetCreditDetail = (currentUserId) => {
     
    return axios
        .get(API_URL + "GetCreditDetail", {
            params: {
                currentUserId: currentUserId
            }
        })
        .then((response) => {
            return response.data;
        });
};

const GetSpecialist = () => {
    return axios
        .get(API_URL + "GetSpecialist")
        .then((response) => {
            //console.log(response.data.Message);
            return response.data;
        });
};
const GetExperience = () => {
    return axios
        .get(API_URL + "GetExperience")
        .then((response) => {
            //console.log(response.data.Message);
            return response.data;
        });
};
const GetIndustries = () => {
    return axios
        .get(API_URL + "GetIndustries")
        .then((response) => {
            //console.log(response.data.Message);
            return response.data;
        });
};
const GetEducation = () => {
    return axios
        .get(API_URL + "GetEducation")
        .then((response) => {
            //console.log(response.data.Message);
            return response.data;
        });
};

const UserEmailRemind = (UserIdList,OperatorId) => {
    return axios
        .post(API_URL + "UserEmailRemind",{UserIdList: UserIdList,OperatorId:OperatorId})
        .then((response) => {
            return response.data;
        });
};
const CreateUsersFile = (UserIdList) => {
    return axios
        .post(API_URL + "CreateUsersFile",{UserIdList: UserIdList})
        .then((response) => {
            return response.data;
        });
};

const GetProLevelList = () => {
    return axios
        .get(API_URL + "GetProLevelList")
        .then((response) => {
            //console.log(response.data.Message);
            return response.data;
        });
};
const GetSectorList = () => {
    return axios
        .get(API_URL + "GetSectorList")
        .then((response) => {
            //console.log(response.data.Message);
            return response.data;
        });
};
const GetGeoInfluenceList = () => {
    return axios
        .get(API_URL + "GetGeoInfluenceList")
        .then((response) => {
            //console.log(response.data.Message);
            return response.data;
        });
};
const GetPositionList = () => {
    return axios
        .get(API_URL + "GetPositionList")
        .then((response) => {
            //console.log(response.data.Message);
            return response.data;
        });
};

const GetStateList = (countryId) => {
    return axios
        .get(API_URL + "GetStateList", { params: { countryId: countryId } })
        .then((response) => {
            //console.log(response.data.Message);
            return response.data;
        });
};


const AddUserDetails = (FirstName, LastName, Email, PhoneNumber, Password, UserJobRole, PartnerId, CompanyId, UserRole, TeamId, JobTitle,program_name,password_reset_mandatory) => {
     
    return axios.post(API_URL + "AddUserDetails", {
        FirstName,
        LastName,
        Email, PhoneNumber,
        Password,   CompanyId, UserJobRole, PartnerId, CompanyId, UserRole, TeamId,JobTitle,program_name,password_reset_mandatory
    });
};


const SaveUserProfileFields = (FirstName, LastName, registration_date, Experience, Industry, Password, Education, Specialist, PhoneNumber, UserJobRole, ProLevel, State, Position, GeoInfluence, JobLevel, PostCode, UserRole, Sector, Country,ReportAccess,ReportTo,PsQualification,SpendUnder,program_name,id,isLoggedIn,) => {

    return axios.post(API_URL + "SaveUserProfileFields", {
        FirstName, LastName, registration_date, Experience, Industry, Password, Education, Specialist, PhoneNumber, UserJobRole, ProLevel, State, Position, GeoInfluence, JobLevel, PostCode, UserRole, Sector, Country,ReportAccess,ReportTo,PsQualification,SpendUnder,program_name,id
    }).then((response) => {
        if (response.data.AccessToken && isLoggedIn) {
            sessionStorage.removeItem("user");
            sessionStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
    });
}

const GetUserProfiledetailsByUserId = (userId) => {
    return axios
        .get(API_URL + "GetUserProfiledetailsByUserId", { params: { userId: userId } })
        .then((response) => {
            //console.log(response.data.Message);
            return response.data;
        });
};
//const GetAllPurchasedAssessmentList = (userId) => {

//    return axios
//        .get(API_URL + "GetAllPurchasedAssessmentList", {
//            params: {
//                userId: userId
//            }
//        })
//        .then((response) => {
//            //console.log(response.data.Message);
//            return response.data;
//        });
//};
const GetUserListByPartnerCompany = (companyId,partnerId) => {
    return axios
        .post(API_URL + "GetUserListByPartnerCompany",{ companyId:companyId,partnerId:partnerId })
        .then((response) => {
            return response.data;
        });
};
const GetQualificationList = () => {
    return axios
        .get(API_URL + "GetQualificationList")
        .then((response) => {
            return response.data;
        });
};
const GetSpendUnderList = () => {
    return axios
        .get(API_URL + "GetSpendUnderList")
        .then((response) => {
            return response.data;
        });
};
const GetReportToList = () => {
    return axios
        .get(API_URL + "GetReportToList")
        .then((response) => {
            return response.data;
        });
};
const GetNewRegistrations = (userId) => {
    return axios
        .post(API_URL + "GetNewRegistrations",{ 'userId':userId})
        .then((response) => {
            return response.data;
        });
};
const GetUserLearningPlansList = (userId,learningPlanId) => {
    return axios
        .post(API_URL + "GetUserLearningPlansList",{ 'userId':userId,'learningPlanId':learningPlanId})
        .then((response) => {
            return response.data;
        });
};

const DeleteAssessmentLearningPlan = (userId,learningPlanId) => {
    return axios
        .post(API_URL + "DeleteAssessmentLearningPlan",{ 'userId':userId,'learningPlanId':learningPlanId})
        .then((response) => {
            return response.data;
        });
};


export default {
    GetUserList, DeleteUserById, UpdateUserRole, AddUserDetails, GetJobRoles, GetUserProfiledetailsByUserId,
    GetSpecialist, GetEducation, GetIndustries, GetExperience, GetProLevelList, GetPositionList,
    GetGeoInfluenceList, GetSectorList, SaveUserProfileFields, GetStateList,GetCreditDetail,
    UserEmailRemind,CreateUsersFile,
    GetUserListByPartnerCompany,
    GetQualificationList,
    GetSpendUnderList,
    GetReportToList,
    GetNewRegistrations,
    GetUserLearningPlansList,
    DeleteAssessmentLearningPlan
};