import React, { useEffect } from "react";
import { Button } from "reactstrap";
import '../pages/auth/css/ResetPassword.css'

function Count({ duration,callback,execute}) {
    // duration 倒计时计数时间单位秒
    // callback 点击时传入的回调方法
    // execute 是否直接倒计时（不用点击触发）true为直接倒计时
  const onClick = callback || function (){}
  const [time, setTime] = React.useState(execute?(duration-1 || 59):(duration || 60));
  const [timeId, setTimeId] = React.useState("");
  useEffect(() => {
    if (time < 1) {
      clearInterval(timeId);
      setTime(duration || 60);
    }
  }, [time]);
  useEffect(() => {
    if(execute) {
      setTimeId(setInterval(() => setTime((time) => time - 1), 1000));    }
  },[])
  /**
   * @description: 点击开始倒计时
   * @return {*}
   */
  const countdown = () => {
    onClick()
    setTime((time) => time - 1);
    setTimeId(setInterval(() => setTime((time) => time - 1), 1000));
  };
  return (
    <div style={{display: 'inline',position: 'absolute',top:'3.3rem'}}>
      {time === duration || time === 0 ? (<div style={{lineHeight: '2.5rem',textAlign:"center",fontSize:'12px',cursor:'pointer'}}>If you haven’t received the email. <span onClick={countdown} style={{color: '#007BFF'}}> Click here to resend</span></div>) :<div style={{lineHeight: '2.5rem',textAlign:"center",fontSize: '12px'}}>If you haven’t received the email. Click here to resend ({time}s)</div> }
    </div>
  );
}

export default Count