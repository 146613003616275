import axios from "axios";

const API_URL = process.env.REACT_APP_TEAM_API_URL;

const GetTeamList = () => {
     
    return axios
        .get(API_URL + "GetTeamList")
        .then((response) => {
            //console.log(response.data.Message);
            return response.data;
        });
};
const GetTeamDetailsById = (id) => {
    return axios
        .get(API_URL + "GetTeamDetailsByTeamId", { params: { teamId: id } })
        .then((response) => {
            //console.log(response.data.Message);
            return response.data;
        });
};
const DeleteTeamById = (id) => {
    return axios.get(API_URL + "DeleteTeamById", { params: { teamId: id } }).then((response) => {
        //console.log(response.data.Message);
        return response.data;
    });
};

const AddTeamDetails = (teamName,teamId,partnerId,companyId, team_description) => {

    var teamDetails = new FormData();
    teamDetails.append("team_name", teamName);
    teamDetails.append("teamId", teamId);
    teamDetails.append("partnerId", partnerId);
    teamDetails.append("companyId", companyId);
    teamDetails.append("team_description", team_description);

    return axios.post(API_URL + "AddTeamDetails", teamDetails);
};


const getCompanyDetailsByPartnerIdTeamViewDropDown = (id) => {
    // 
    return axios
        .get(API_URL + "getCompanyDetailsByPartnerIdTeamViewDropDown", { params: { partnerId: id } })
        .then((response) => {
            //console.log(response.data);
            return response.data;
        });
};

const getUserDetailsByCompanyIdTeamViewDropDown = (id) => {
  
    return axios
        .get(API_URL + "getUserDetailsByCompanyIdTeamViewDropDown", { params: { companyId: id } })
        .then((response) => {
            //console.log(response.data);
            return response.data;
        });
};

const GetTeamPerformance = (userId) => {
    return axios
        .post(API_URL + "GetTeamPerformance",{"userId":userId})
        .then((response) => {
            return response.data;
        });
};

export default {
    GetTeamList, DeleteTeamById, AddTeamDetails, GetTeamDetailsById, getCompanyDetailsByPartnerIdTeamViewDropDown, getUserDetailsByCompanyIdTeamViewDropDown,
    GetTeamPerformance
};